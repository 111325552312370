<template>
    <div v-if="visible" class="custom-alert" :class="type">
        <div class="alert-content">
            <p>{{ message }}</p>
            <button class="text-black" @click="closeAlert"><span class="text-black text-xs">Close</span></button>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: 'info', // 'success', 'error', 'warning', etc.
        },
        message: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const visible = ref(true);

        const closeAlert = () => {
            visible.value = false;
            emit('close'); // Emit an event if needed.
        };

        return {
            visible,
            closeAlert,
        };
    },
});
</script>

<style>
.custom-alert {
    position: fixed;
    top: 20px;
    right: 28%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #fff;
    z-index: 999;
}

.custom-alert.info {
    background-color: #2196f3;
}

.custom-alert.success {
    background-color: #acecae;
    border-color: #63db67
}

.custom-alert.error {
    background-color: #f44336;
}

.custom-alert.warning {
    background-color: #ff9800;
}

.alert-content button {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #fff;
    color: inherit;
    cursor: pointer;
    border-radius: 3px;
}
</style>