<template>
    <div class="group peer relative dropdown-container flex items-center justify-between gap-2 font-medium marker:content-none hover:cursor-pointer   "
        :ref="props.ref_">
        <div @click="handleToggle"
            :class="props.faq == true ? 'px-2 py-5 bg-[#1C2242] rounded-2xl' : 'px-2 py-3  bg-dropdown-1 rounded-lg '"
            class="flex w-full  summary items-center justify-between border-border-dropdown-1 border-2">
            <span class="flex gap-2">
                <div class="flex items-center pl-2 font-bold font-proxima text-xs">
                    <div v-if="faq == true" class="h-1 w-1 bg-[#7F809E] rounded-full inline-block mr-3"></div>
                    <img v-if="props.icon" class="w-auto h-4 pr-3" :class="props.icon == 'icon-game' ? 'filter' : ''"
                        :src="props.icon != 'icon-game'
                            ? require(`../assets/${props.icon}.png`)
                            : require(`../assets/${props.icon}.svg`)
                            " />
                    <span :class="props.faq != true
                        ? 'text-dropdown-1-text-color'
                        : 'text-[#7F809E] font-proxima font-bold text-lg'
                        ">{{ text }}
                    </span>
                </div>
            </span>
            <svg class="w-4 h-4 text-arrow-color transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                </path>
            </svg>
        </div>
        <div class="dropdown-content absolute px-10 rounded-b-md bg-gray-100 " style="" v-if="isOpen">
            <!-- Added a relative container -->

            <ul class="list gap-4 pl-2 bg-[#3a3f61] rounded-b-md max-h-52 overflow-y-auto" style="
   
  position: absolute;
    left: 0px;
    width: 100%;
    top: 17px;
    z-index: 10;
    ">
                <li v-for="(item, index) in items_list" :key="index" @click="handleItemClick(item, text)" :class="props.faq == true
                    ? 'flex justify-start pl-6 text-base text-[#7F809E]'
                    : 'text-[#DAD4FF]  text-xs flex justify-start '
                    " class="gap-2 font-proxima pl-5">
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>

</template>

<script setup>
import { ref, computed } from 'vue';
const props = defineProps(['text', 'icon', 'items_list', 'faq', 'dropdownId', 'openDropdown', 'ref_'])
const emit = defineEmits(['updateModal', 'update:openDropdown'])
const detailsRef = ref(null);
const show_item = ref(true)
const itemClick = ref(false)
const checkUpdate = (item, text) => {
    emit('updateModal', item, text)
}
const handleToggle = () => {
    show_item.value = true

    // If this dropdown is already open, close it; otherwise, open it
    const newOpenDropdown = props.openDropdown === props.dropdownId && itemClick.value == false ? null : props.dropdownId;
    itemClick.value = false

    emit('update:openDropdown', newOpenDropdown);
};
const isOpen = computed(() => props.openDropdown === props.dropdownId && show_item.value == true);

const handleItemClick = (item, text) => {
    checkUpdate(item, text);
    show_item.value = false
    itemClick.value = true
    // Close the <details> element
}


</script>

<style scoped>
.list {
    list-style-type: none;
    padding: 0;
    display: grid;
    gap: 10px;
    /* Space between the columns */
}

.filter {
    filter: invert(22%) sepia(17%) saturate(1590%) hue-rotate(190deg) brightness(102%) contrast(94%);
}

li::marker {
    display: none;
}

.dropdown-container {
    width: 100%;
}

.dropdown-content {
    width: 100%
}
.list li {
    margin: 5px 0;
}
/* Add any additional styling here if needed */
</style>
