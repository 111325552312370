<template>
    <div class="no-scrollbar z-2 w-[full] px-4 overflow-x-hidden ">
        <TextBoxVue :isSearch="true" :type="'text'" :noSpace="true" :icon="'search-white'" :placeHolder="'Search Game'"
            @updateField="getText" />
        <div class="pt-3">
            <DropDownVue :ref_="'ref1'" :dropdownId="'dropDown1'" :faq="false"
                :text="PV ? `Provider: ${PV}` : 'Provider'" :items_list="providerNames" @updateModal="getItemProvider"
                :openDropdown="openDropdown" @update:openDropdown="openDropdown = $event" />
        </div>

        <div class="pt-3">

            <DropDownVue :ref_="'ref2'" :dropdownId="'dropDown2'" :faq="false"
                :text="GT ? `Game Type: ${GT}` : 'Game Type'" :items_list="gameTypeNames" @updateModal="getItemGameType"
                @updateRef="update_ref2" :openDropdown="openDropdown" @update:openDropdown="openDropdown = $event" />
        </div>


        <!-- <div class="flex mt-5 justify-center text-sm items-center gap-4 rounded-full font-righteous">
            <div class="min-w-16 clickable" :class="category == 0 ? 'bubble-border h-full' : null">
                <button :class="category == 0
                    ? 'bubble-inner h-full  flex items-center justify-center px-3 py-2 text-white'
                    : null
                    " @click="setCategory(0)" :disabled="Object.keys(searchGames).length === 0">
                    Game Type
                </button>
            </div>
            <div class="min-w-16 clickable" :class="category == 1 ? 'bubble-border h-full' : null">
                <button :class="category == 1
                    ? 'bubble-inner h-full  flex items-center justify-center px-3 py-2 text-white'
                    : null
                    " @click="setCategory(1)" :disabled="Object.keys(searchGames).length === 0">
                    Provider
                </button>
            </div>
            <button class="pl-[40px]" @click="remSelect" v-if="PV != '' || GT != ''"
                :disabled="Object.keys(searchGames).length === 0">
                Back
            </button>
        </div> -->
        <div ref="scrollableContent" class=" no-scrollbar overflow-y-auto h-[100vh] pb-11 " @scroll="checkScroll">

            <div class="grid gap-6 grid-cols-3 md:grid-cols-6  pb-5 pt-3 overflow-y-auto">

                <button :disabled="!auth.currentUser?.uid"
                    class="border-dropdown-1 py-1 gap-7 overflow-y-auto hover:bg-slate-700 bg-profile-deposit rounded-xl"
                    v-for="(item, index) in filteredGames" :key="index" @click="openIframe(item, $event)">
                    <img class="rounded-xl" v-lazy="dashboardStore.isTournaments == false ? item.images[2]?.url : `https://client.qtlauncher.com/images/?id=${item.name}_en_US&type=logo-square&version=1721729499778`
                        " alt="" />
                </button>
            </div>


        </div>

    </div>

    .
</template>

<script setup>
import TextBoxVue from '@/components/form/TextBox.vue'
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
import { useDashboardStore } from '@/store/dashboardStore'
import debounce from 'lodash/debounce'
import LoadingVue from '../LoadingVue.vue'
import DropDownVue from '../DropDownVue.vue'
import { auth, supabase } from '@/main';
import { addUniqueObjects } from '@/helper/helper'
import { componentKey } from '@/App.vue'


const triggerReload = () => {
    dashboardStore.setComponentKey()
}
const dashboardStore = useDashboardStore()
const text = ref('')
const images = ref([
    require('../../assets/img-1.png'),
    require('../../assets/img-2.png'),
    require('../../assets/img-3.png'),
    require('../../assets/img-4.png'),
    require('../../assets/img-5.png'),
    require('../../assets/img-6.png'),
    require('../../assets/img-7.png')
])
const gameProviders = [
    {
        id: 'SLG',
        name: 'SpinLogic Gaming',
        img: 'https://lobby.qtlauncher.com/images/?id=SLG_en_US&type=providers&version=1728053882027&theme=dark&format=avif&newItem=true'

    },
    {
        id: '1X2',
        name: '1X2 Gaming',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: '7ML',
        name: '7ML Gaming',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: '7MC',
        name: '7MC Gaming',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'BTL',
        name: 'BTL Games',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        name: 'Blueprint Gaming',
        id: 'BPG',
        img: 'https://cdn2.softswiss.net/logos/providers/white/blueprint.svg'
    },
    {
        name: 'Evoplay',
        id: 'EVP',
        img: 'https://cdn2.softswiss.net/logos/providers/white/evoplay.svg'
    },
    {
        id: 'EZU',
        name: 'EZU Games',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        name: 'Fantasma Games',
        id: 'FNG',
        img: 'https://cdn2.softswiss.net/logos/providers/white/fantasma.svg'
    },
    {
        name: 'GAMEART',
        id: 'GA',
        img: 'https://cdn2.softswiss.net/logos/providers/white/gameart.svg'
    },
    {
        name: 'Habanero',
        id: 'HAB',
        img: 'https://cdn2.softswiss.net/logos/providers/white/habanero.svg'
    },
    {
        id: 'MSG',
        name: 'MSG Games',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'MOB',
        name: 'MOB Games',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'NAG',
        name: 'NAG Entertainment',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'NGE',
        name: 'NGE Studio',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        name: 'Nolimit City',
        id: 'NLC',
        img: 'https://cdn2.softswiss.net/logos/providers/white/nolimit.svg'
    },
    {
        id: 'RTG',
        name: 'RTG Interactive',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'SAG',
        name: 'SAG Productions',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        name: 'Slotmill',
        id: 'SM',
        img: 'https://cdn2.softswiss.net/logos/providers/white/slotmill.svg'
    },
    {
        id: 'SMS',
        name: 'SMS Gaming',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'TRB',
        name: 'TRB Games',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        name: 'Thunderkick',
        id: 'TK',
        img: 'https://cdn2.softswiss.net/logos/providers/white/thunderkick.svg'
    },
    {
        id: 'VGL',
        name: 'VGL Studios',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        name: 'Wazdan',
        id: 'WAZ',
        img: 'https://cdn2.softswiss.net/logos/providers/white/wazdan.svg'
    },
    {
        id: 'WOO',
        name: 'WOO Games',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'YGG',
        name: 'YGG Entertainment',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        name: 'Pragmatic Play Casino',
        id: 'PPC',
        img: 'https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg'
    },
    {
        name: 'Big Time Gaming',
        id: 'BTG',
        img: 'https://cdn2.softswiss.net/logos/providers/white/bigtimegaming.svg'
    },
    {
        name: 'Hacksaw Gaming',
        id: 'HAK',
        img: 'https://cdn2.softswiss.net/logos/providers/white/hacksaw.svg'
    },
    {
        name: 'NetEnt',
        id: 'NE',
        img: 'https://cdn2.softswiss.net/logos/providers/white/netent.svg'
    },
    {
        name: 'Red Tiger',
        id: 'RED',
        img: 'https://cdn2.softswiss.net/logos/providers/white/redtiger.svg'
    },
    {
        id: 'SWL',
        name: 'SWL Games',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'SWC',
        name: 'SWC Gaming',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        id: 'EYC',
        name: 'EYC Entertainment',
        img: 'https://cdn2.softswiss.net/logos/providers/white/default.svg'
    },
    {
        name: 'Spribe',
        id: 'SPB',
        img: require('@/assets/SPB.png')
    },
    {
        name: 'Evolution Gaming',
        id: 'EVO',
        img: 'https://cdn2.softswiss.net/logos/providers/white/evolution.svg'
    },
    {
        name: 'Pragmatic Play Live',
        id: 'PPL',
        img: 'https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg'
    }

];

const showIframe = ref(true);

const props = defineProps('')

const closeIframe = () => {
    showIframe.value = false;
};
function handleEscKey(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
        closeIframe()
        // Perform your action here, e.g., close a modal or clear input
        console.log('ESC pressed');
    }
}
onMounted(() => {
    window.addEventListener('keydown', handleEscKey);

})

onUnmounted(() => {


    window.removeEventListener('keydown', handleEscKey);
});

const scrollableContent = ref(null)
const openDropdown = ref(null);

function showBackButton() {
    // window.location.reload();
    window.parent.postMessage("showBackButton", "*");
}
function getNameById(id) {
    const foundObject = gameProviders.find(obj => obj.id === id);
    return foundObject ? foundObject.name : null; // Return null if no match is found
}
const checkScroll = () => {
    if (!scrollableContent.value || !loadMoreTrigger.value) {
        return
    }

    const triggerPosition = loadMoreTrigger.value.getBoundingClientRect().top
    const contentPosition = scrollableContent.value.getBoundingClientRect().bottom

    if (triggerPosition <= contentPosition) {
        showLoadMore.value = true
    } else {
        showLoadMore.value = false
    }
}

const loadMoreTrigger = ref(null)
const showLoadMore = ref(false)
const results = ref([])
const isGt = ref(true)
const GT = ref(dashboardStore.selectedGT)
const PV = ref(getNameById(dashboardStore.selectedPV) != '' ? getNameById(dashboardStore.selectedPV) : '')
const iframeOpened = ref(false)


const openIframe = (item, event) => {

    showIframe.value = true
    dashboardStore.setIndividualBoolean(true)
    if (dashboardStore.isTournaments == false)
        dashboardStore.getGameUrl(item.id)
    else dashboardStore.getGameUrl(item.name)
    showBackButton()
    if (dashboardStore.showIframe == false) {
        dashboardStore.setShowIframe(true)
        dashboardStore.setLoading(false)

    }
}


watch(
    () => dashboardStore.showIframe == true && showIframe.value,
    (newVal) => {
        if (newVal) {
            // Trigger the action when the component becomes visible
            dashboardStore.setIndividualBoolean(false)
        }
    }
);

const update_ref1 = () => {

    ref2.value = false
    ref1.value = false
}

const update_ref2 = () => {
    console.log("222")
    ref2.value = false
    ref1.value = false

    console.log(ref2.value)
}
const getText = debounce(async (val) => {
    setCategory(2);
    text.value = val?.text ?? text.value; // Use an empty string if val or val.text is undefined
    const { data, error } = await supabase
        .from('qt_games')
        .select('*').ilike("name", `%${text.value}%`).limit(50)
    console.log("DATA", data)
    dashboardStore.setGameList2(data)
    dashboardStore.setSearchList(data)
    if (val && val.text) {
        searchNames(data);
    }
}, 300);

const searchNames = query => {
    console.log(query)
    if (text.value) {
        results.value = []
        return
    }
    const idsArray = query.map(obj => obj.id);
    console.log("QUERY", idsArray)
    const filteredResults = query
    console.log("QUERY", query)

    dashboardStore.setGameList(query)
    const uniqueArray = filteredResults.filter(
        (item, index, self) => index === self.findIndex(t => t.id === item.id)
    )
    // Assign the filtered results
    results.value = uniqueArray

    // console.log(dashboardStore.searchList[0])
    // results.value = dashboardStore.searchList.filter(item => {
    //     console.log(item.name.includes(query))
    //     item.name.includes(query)
    // })
    // console.log(results.value)
}

//Net ent and fugaso needs img


function getIdByName(name) {
    const foundObject = gameProviders.find(obj => obj.name === name);
    return foundObject ? foundObject.id : null; // Returns null if not found
}

const gameTypes = [
    { BINGO: 'bingo' },
    { CASUALGAME: 'casualgame' },
    { ESPORTS: 'esports' },
    { INSTANTWIN: 'instantwin' },
    { LIVECASINO: 'livecasino' },
    { SCRATCHCARD: 'scratchcard' },
    { SHOOTING: 'shooting' },
    { SLOT: 'slot' },
    { SPORTS: 'sports' },
    { TABLEGAME: 'tablegame' },
    { VIDEOPOKER: 'videopoker' },
    { VIRTUAL_SPORTS: 'virtual sports' },
    { LOTTERY: 'lottery' },
    { CRASH: 'crash' },
    { GAME_SHOW: 'game show' }
];

let providerNames = computed(() => gameProviders.map(provider => provider.name));
providerNames.value.unshift('All')
let gameTypeNames = computed(() => gameTypes.map(item => Object.keys(item)[0]));
gameTypeNames.value.unshift('All')
const ref1 = ref(null)
const ref2 = ref(null)
const getItemGameType = val => {
    ref2.value = false
    isGt.value = true
    console.log("GT", val)

    GT.value = val
    fetchData()
    // category.value=2
}

const getItemProvider = val => {
    ref1.value = false
    isGt.value = false
    console.log("prov", val)
    PV.value = val
    fetchData()
    // category.value=2
}

const allowedProviderIds = [
    'SLG',
    'PPC',
    'EVO',
    'HAK',
    'SPB',
    'BPG',
    'HAB',
    'NLC',
    'TK',
    'BTL',
    'EVP',
    'EZU',
    'FNG',
    'SWC',
    'GA',
    'PPL',
    'MSG',
    'MOB',
    'NAG',
    'NGE',
    'SAG',
    'SM',
    'SMS',
    'TRB',
    'VGL',
    'WAZ',
    'WOO',
    '1X2',
    '7ML',
    '7MC',
    'BTG',
    'NE',
    'RED',
    'SWL',
    'EYC'
]

const fetchData = async () => {
    console.log("GT PV TEXT", GT.value, PV.value, text.value);

    // Construct the query
    let query = supabase
        .from('qt_games')
        .select('*')
    // Limit results to avoid overwhelming the database

    // Add filters conditionally
    if (GT.value !== '' && GT.value != 'All') {
        query = query.ilike('category', `%${GT.value}%`); // Case-insensitive match for category
    }
    if (PV.value !== '' && PV.value != 'All') {
        const id = getIdByName(PV.value);
        query = query.in('provider_id', id ? [id] : allowedProviderIds); // Use provider filter if available
    }
    if (text.value !== '') {
        query = query.ilike('name', `%${text.value}%`); // Case-insensitive match for game name
    }

    // Execute the query
    const { data, error } = await query;

    if (error) {
        console.error('Error fetching games:', error);
        return;
    }

    console.log('Filtered results:', data);
    dashboardStore.setGameList2(data)
    dashboardStore.setSearchList(data); // Update the search list
};




const filteredGames = computed(() => {
    if (dashboardStore.isTournaments == true) {

        const mappedGames = dashboardStore.selectedTournament.games.map((game, index) => ({ id: index + 1, name: game }));
        console.log("MAPPED GAMES", mappedGames

        )
        return mappedGames
    } else {
        if (dashboardStore.gameList && dashboardStore.gameList.items.length > 0) {
            console.log("GAMELIST", dashboardStore.gameList)
            let uniqueArray = dashboardStore.gameList.items
            console.log("text GAMES", text.value)

            uniqueArray = uniqueArray.filter(
                (item, index, self) => index === self.findIndex(t => t.id === item.id)
            );
            console.log("UNIQUE0", uniqueArray)
            return uniqueArray
        } else {
            let uniqueArray = GT.value == '' && PV.value == '' && text.value == '' ? [] : dashboardStore.searchList
            console.log("UNIQU", uniqueArray)
            // Filter by text if there's a value in `text`
            console.log("TEXT games", text.value)
            if (text.value) {
                uniqueArray = uniqueArray.filter(item =>
                    item.name.toLowerCase().includes(text.value.toLowerCase())
                );
            }

            uniqueArray = uniqueArray.filter(
                (item, index, self) => index === self.findIndex(t => t.id === item.id)
            );

            return uniqueArray;
        }
    }


});

onMounted(() => {

    setTimeout(() => {
        dashboardStore.setLoadingModal(false)
    }, 500)
    dashboardStore.getBalance()
})
const category = ref(0)
const currentIndex = ref(0)

const setCategory = val => {
    category.value = val
}

const sliderStyle = computed(() => {
    return `transform: translateX(-${currentIndex.value * 100}%)`
})
const isSearch = computed(() => {
    if (text.value != '') {
        return true
    } else return false
})
const canSlideLeft = computed(() => {
    return currentIndex.value > 0
})

const remSelect = () => {
    GT.value = ''
    PV.value = ''
}
const canSlideRight = computed(() => {
    return (currentIndex.value + 1) * 5 < images.value.length
})

const slideLeft = () => {
    if (canSlideLeft.value) {
        currentIndex.value--
    }
}
onUnmounted(() => {
    PV.value = ''
    GT.value = ''
})
const slideRight = () => {
    if (canSlideRight.value) {
        currentIndex.value++
    }
}
</script>

<style scoped>
/* Custom checkbox tick color */
input[type='checkbox']:checked {
    background-color: #131931; /* Green background when checked */
    border-color: #1e284a; /* Green border when checked */
    box-shadow: 0px 12px 16px 0px #0000000f;
}
input[type='checkbox']:disabled:focus {
    box-shadow: none;
    border-color: #1e284a; /* Green border when checked */
}

input[type='checkbox']:focus {
    box-shadow: none !important;
}
.custom-checkbox input[type='checkbox'] {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #131931;
    border: 2px solid #1e284a;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.custom-checkbox input[type='checkbox']:checked {
    border-color: #1e284a; /* Green border when checked */
}

.custom-checkbox input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 39%;
    left: 47%;
    transform: translate(-50%, -50%) rotate(44deg) scaleX(1);
    width: 6px;
    height: 11px;
    border: solid #00ff94;
    box-shadow: 0px 0px 0px 0px #00ff9473;
    border-width: 0 3px 3px 0;
}

.custom-checkbox label {
    font-size: 11px;
    padding-left: 5px;
}

.filter-dis {
    filter: brightness(0) saturate(100%) invert(24%) sepia(8%) saturate(1784%) hue-rotate(187deg)
        brightness(94%) contrast(87%);
}

.filter-en {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(51deg)
        brightness(101%) contrast(101%);
}
/* Hide default checkbox appearance and style the new one */
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.5rem;
    accent-color: #4caf50; /* Change tick color */

    height: 1.5rem;
    background-color: #131931; /* Green background when checked */
    border-radius: 0.375rem; /* Tailwind rounded */
    border-color: #4caf50; /* Green border when checked */
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;
}

.bubble-border {
    padding: 2px;
    border-radius: 22px;
    background: linear-gradient(180deg, #4af3ff 0%, #1d99a2 100%);
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.bubble-inner {
    background: linear-gradient(180deg, #00e6f6 0%, #08777e 100%);
    border-radius: 20px;
}
</style>
