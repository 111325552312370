<template>
    <div class="text-primary w-full">
        <div class="overflow-y-auto no-scrollbar h-screen outerbox rounded-l-xl">
            <div class="grid px-14 pt-9">
                <div class="flex pt-6 justify-center items-center text-[30px] font-righteous pb-2">
                    <img class="max-h-5 pr-1" src="../assets/darts.png" />
                    <span class="text-white pr-1">Multi-provider </span
                    ><span class="text-[#00e6f6]">Campaign</span>
                </div>

                <div class="flex justify-center gap-2">
                    <div class="rounded-lg">
                        <div class="flex justify-center rounded-t-lg">
                            <img class="rounded-t-xl" src="../assets/1080x829EN-1-1.jpg" alt="" />
                        </div>

                        <div class="bg-[#262626] max-w-[1080px] pb-4 rounded-b-xl">
                            <div class="pt-4">
                                <h2
                                    style="
                                        margin: 0;
                                        padding: 0;
                                        line-height: 1.65;
                                        font-size: 35px;
                                        font-weight: 700;
                                        font-family: sans-serif;
                                        color: #e8c053;
                                    "
                                >
                                    USD 34,000
                                </h2>
                                <p
                                    style="
                                        text-align: center;
                                        font-family: sans-serif;
                                        font-size: 20px;
                                        color: #8e97a0;
                                    "
                                    class="px-10"
                                >
                                    1 SEP - 4 OCT
                                </p>
                                <p class="text-[16px] px-[7rem] font-roboto text-white text-center">
                                    Let the games begin! Join QTech Games' multi-provider
                                    tournament, featuring top-tier games from our best providers.
                                    This 4-series event includes Live Dealer, Slot games, and Crash
                                    games, with our largest prize pool to date—a staggering USD
                                    34,000.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex pt-6 justify-center items-center text-[30px] font-righteous pb-2">
                    <img class="max-h-5 pr-1" src="../assets/darts.png" />
                    <span class="text-white pr-1">Single-provider </span
                    ><span class="text-[#00e6f6]">Campaign</span>
                </div>

                <div class="flex justify-center gap-3">
                    <div class="rounded-lg">
                        <div class="flex justify-center rounded-t-lg">
                            <img class="rounded-t-xl" src="../assets/heatwave.png" alt="" />
                        </div>

                        <div class="bg-[#262626] max-w-[1080px] pb-4 rounded-b-xl">
                            <div class="pt-4">
                                <h2
                                    style="
                                        margin: 0;
                                        padding: 0;
                                        line-height: 1.65;
                                        font-size: 35px;
                                        font-weight: 700;
                                        font-family: sans-serif;
                                        color: #e8c053;
                                    "
                                >
                                    USD 7,000
                                </h2>
                                <p
                                    style="
                                        text-align: center;
                                        font-family: sans-serif;
                                        font-size: 20px;
                                        color: #8e97a0;
                                    "
                                    class="px-10"
                                >
                                    8 AUG - 14 AUG
                                </p>
                                <p class="text-[16px] px-[7rem] font-roboto text-white text-center">
                                    Blazing wins await as your players feel the heat in Dragoon
                                    Soft’s “Heat Wave”, a 7-day tournament with a scorching hot
                                    prize pool of USD 7,000. Let the heat of this tournament propel
                                    players to victory. The heat is on and the wins are waiting.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="rounded-lg">
                        <div class="flex justify-center rounded-t-lg">
                            <img class="rounded-t-xl" src="../assets/fallfortune.jpg" alt="" />
                        </div>

                        <div class="bg-[#262626] max-w-[1080px] pb-4 rounded-b-xl">
                            <div class="pt-4">
                                <h2
                                    style="
                                        margin: 0;
                                        padding: 0;
                                        line-height: 1.65;
                                        font-size: 35px;
                                        font-weight: 700;
                                        font-family: sans-serif;
                                        color: #e8c053;
                                    "
                                >
                                    USD 7,000
                                </h2>
                                <p
                                    style="
                                        text-align: center;
                                        font-family: sans-serif;
                                        font-size: 20px;
                                        color: #8e97a0;
                                    "
                                    class="px-10"
                                >
                                    5 OCT - 11 OCT
                                </p>
                                <p class="text-[16px] px-[7rem] font-roboto text-white text-center">
                                    Leaves are falling, slots are calling! Let your players
                                    experience the fall fun as they compete in Fall Fortune
                                    featuring Slotmill's slot games. Let the season of fortune begin
                                    in this 7-day, USD 7,000 prize pool tournament.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex pt-6 justify-center items-center text-[30px] font-righteous pb-2">
                    <img class="max-h-5 pr-1" src="../assets/darts.png" />
                    <span class="text-white pr-1">Provider </span
                    ><span class="text-[#00e6f6]">Network</span>
                </div>
                
                <div class="flex justify-center gap-3">
                    <div class="rounded-lg">
                        <div class="flex justify-center rounded-t-lg">
                            <img class="rounded-t-xl" src="../assets/heatwave.png" alt="" />
                        </div>

                        <div class="bg-[#262626] max-w-[1080px] pb-4 rounded-b-xl">
                            <div class="pt-4">
                                <h2
                                    style="
                                        margin: 0;
                                        padding: 0;
                                        line-height: 1.65;
                                        font-size: 35px;
                                        font-weight: 700;
                                        font-family: sans-serif;
                                        color: #e8c053;
                                    "
                                >
                                    USD 7,000
                                </h2>
                                <p
                                    style="
                                        text-align: center;
                                        font-family: sans-serif;
                                        font-size: 20px;
                                        color: #8e97a0;
                                    "
                                    class="px-10"
                                >
                                    8 AUG - 14 AUG
                                </p>
                                <p class="text-[16px] px-[7rem] font-roboto text-white text-center">
                                    Blazing wins await as your players feel the heat in Dragoon
                                    Soft’s “Heat Wave”, a 7-day tournament with a scorching hot
                                    prize pool of USD 7,000. Let the heat of this tournament propel
                                    players to victory. The heat is on and the wins are waiting.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="rounded-lg">
                        <div class="flex justify-center rounded-t-lg">
                            <img class="rounded-t-xl" src="../assets/fallfortune.jpg" alt="" />
                        </div>

                        <div class="bg-[#262626] max-w-[1080px] pb-4 rounded-b-xl">
                            <div class="pt-4">
                                <h2
                                    style="
                                        margin: 0;
                                        padding: 0;
                                        line-height: 1.65;
                                        font-size: 35px;
                                        font-weight: 700;
                                        font-family: sans-serif;
                                        color: #e8c053;
                                    "
                                >
                                    USD 7,000
                                </h2>
                                <p
                                    style="
                                        text-align: center;
                                        font-family: sans-serif;
                                        font-size: 20px;
                                        color: #8e97a0;
                                    "
                                    class="px-10"
                                >
                                    5 OCT - 11 OCT
                                </p>
                                <p class="text-[16px] px-[7rem] font-roboto text-white text-center">
                                    Leaves are falling, slots are calling! Let your players
                                    experience the fall fun as they compete in Fall Fortune
                                    featuring Slotmill's slot games. Let the season of fortune begin
                                    in this 7-day, USD 7,000 prize pool tournament.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import CarrouselVue from './carrouselVue.vue'

const images = ref([
    require('../assets/fiesta-de-yggdrasil_1388x420_kr+krw.jpg'),
    'https://picsum.photos/id/238/1024/800',
    'https://picsum.photos/id/239/1024/800'
])
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.image-border {
    border-radius: 20px;
}

.banner-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #1d58ca 100%);
}

.banner-inner {
    background: linear-gradient(180deg, #1a154d 19.44%, #044e66 100%);
}

.box-warrior {
    background: linear-gradient(
        120deg,
        rgba(192, 112, 21, 1) 0%,
        rgba(183, 117, 95, 1) 100%,
        rgba(186, 120, 95, 1) 100%
    );

    /* mask */
}

.outerbox {
    border-color: theme('colors.border-dropdown-1');
    background: theme('colors.dropdown-1');
}
</style>
