import { defineStore } from 'pinia'
import axios from 'axios'
import { addUniqueObjects, findMatchingItems } from '@/helper/helper'
import { auth, qtSelectedCurrency, db, coinSupported, supabase } from '@/main'
import { doc, getDoc } from 'firebase/firestore'

// Set default headers for all requests
const providerOrder = ['HAK', 'EVO', 'PPC']
const allowedProviderIds = [
    'SLG',
    'PPC',
    'EVO',
    'HAK',
    'SPB',
    'BPG',
    'HAB',
    'NLC',
    'TK',
    'BTL',
    'EVP',
    'EZU',
    'FNG',
    'SWC',
    'GA',
    'PPL',
    'MSG',
    'MOB',
    'NAG',
    'NGE',
    'SAG',
    'SM',
    'SMS',
    'TRB',
    'VGL',
    'WAZ',
    'WOO',
    '1X2',
    '7ML',
    '7MC',
    'BTG',
    'NE',
    'RED',
    'SWL',
    'EYC'
]
interface ModalState {
    modalName: string
    modalOpen: boolean
    header: string
}

interface Balance {
    balance: number
    currency: string
}

interface gameList {
    items: any[]
}

// Define an interface for the state
interface DashboardState {
    category: number
    modal: ModalState
    gameList: gameList
    popularGames: Array<any>
    lastPlayed: Array<any>
    sideMenuList: any
    selectedGT: string
    selectedPV: string
    gameUrl: string
    selectedCoin: string
    balance_selected: any
    coinSupported: Array<any>
    nextLink: any
    prevLink: any
    selectedTab: number
    filteredProperty: string
    linkHistory: Array<any>
    statistics: any
    showIframe: boolean
    sideList: Array<any>
    livebets: Array<any>
    previousbets: Array<any>
    currentLink: string | null
    pageDashboard: number
    isTournaments: boolean
    pageDashboardReal: number
    isLoading: boolean
    nextLinkSide: string
    selectedTournament: any
    currentGame: any
    sideGameOpened: boolean
    searchList: Array<any>
    multipliers: Array<any>
    balance: Balance
    individualLoading: boolean
    hasNext: boolean
    currency: string
    loading_modal: boolean
    popular: boolean
    showChat: boolean
    totalCount: number
    componentKey: number
    filter_category: string
    crypto_address: any
    ethMultiplier: number
    crypto: any
    amount: number
    sideCat: string
    errors: Array<any>
    loading: boolean
    checkCanToggle: boolean
}

// Define an interface for the actions
interface DashboardActions {
    setCat(cat: number): void
    increment(): void
    setModalOpen(val: boolean, name: string | ''): void
    getGameList(params: any, url: string, component: string, pagination: any): Promise<any>
    getGameUrl(id: string): Promise<any>
    selectGameCurrency(currencyId: string): Promise<void>
    getGameTransactions(): void
    getStatistics(): void
    setCoinSupported(coinSupported: any): void
    setComponentKey(): void
    setSelectedTab(val: number): void
    setShowIframe(val: boolean): void
    setSelectedCoin(val: string): void
    setSelectedTournament(val: any): void
    setSelectedPV(val: string): void
    setSelectedGT(val: string): void
    setIsTournaments(val: boolean): void
    setLastPlayed(val: any): void
    getMostPopularList(
        params: any,
        url: string,
        component: string,
        pagination: string
    ): Promise<any>
    setHeader(header: string): void
    setGameList(list: any): void
    setSideMenuList(list: any): void
    getNextGameList(params: any, component: string, cat: number): Promise<void>
    getEthMultiplier(coin: string): Promise<void>
    getPrevGameList(params: any, component: string, cat: number): Promise<void>
    setLinkSide(link: string): void
    setLink(link: string): void
    setLoadingModal(val: boolean): void
    setCheckToggle(val: boolean): void
    setBalanceState(val: any): void
    addItemSide(item: any): void
    setSideGame(val: boolean): void
    getBalance(): Promise<void>
    setBalance(val: number, cur: string): void
    setShowchat(val: boolean): void
    setPageDashboard(val: number): void
    resetLinkHistory(): void
    setFilterCategory(val: string): void
    setSearchList(val: any): void
    plusPageDashboard(val: number): void
    setCryptoAddress(val: any): void
    setCrypto(val: any): void
    setAmount(val: number): void
    setGameUrl(val: string): void
    setCurrency(val: string): void
    setSideCat(str: string): void
    getLastPlayed(): void
    setLiveBets(val: any): void
    setLoading(val: boolean): void
    setErrors(err: string): void
    setMultipliers(val: any): void
    setEthMultiplier(val: number): void
    setStatistics(val: any): void
    setGameList2(list: any): void
    setIndividualBoolean(val: boolean): void
    setFilteredProperty(val: string): void
}

// Define an interface for the getters
type DashboardGetters = {
    categoryVal: (state: DashboardState) => number
}
// Define the store
export const useDashboardStore = defineStore<
    'dashboard',
    DashboardState,
    DashboardGetters,
    DashboardActions
>('dashboard', {
    state: (): DashboardState => ({
        category: 0,
        crypto_address: {},
        selectedCoin: 'ETH',
        loading_modal: false,
        checkCanToggle: false,
        crypto: {},
        modal: { modalName: '', modalOpen: false, header: '' },
        gameList: { items: [] },
        showIframe: false,
        multipliers: [],
        gameUrl: '',
        selectedTournament: {},
        statistics: {},
        nextLink: '',
        ethMultiplier: 0,
        selectedGT: '',
        lastPlayed: [],
        selectedPV: '',
        selectedTab: 0,
        isTournaments: false,
        filteredProperty: '',
        prevLink: '',
        nextLinkSide: '',
        totalCount: 0,
        sideMenuList: {},
        individualLoading: false,
        linkHistory: [''],
        errors: [],
        popular: false,
        componentKey: 0,
        coinSupported: [],
        filter_category: '',
        popularGames: [],
        livebets: [],
        previousbets: [],
        showChat: false,
        pageDashboard: 0,
        pageDashboardReal: 0,
        searchList: [],
        currentLink: null,
        isLoading: false,
        currentGame: undefined,
        sideCat: '',
        sideList: [],
        sideGameOpened: false,
        hasNext: false,
        amount: 0,
        currency: '',
        loading: false,
        balance_selected: {},
        balance: {
            currency: '',
            balance: 0
        }
    }),
    getters: {
        categoryVal: (state): number => state.category
    },
    actions: {
        setLastPlayed(val: any) {
            this.lastPlayed = val
        },
        setIsTournaments(val: boolean): void {
            this.isTournaments = val
        },
        setComponentKey() {
            this.componentKey++
        },
        setSelectedTournament(val: any): void {
            this.selectedTournament = val
        },
        setLoading(val: boolean) {
            this.loading = val
        },
        setCheckToggle(val: boolean): void {
            this.checkCanToggle = val
        },
        setSelectedTab(val: number): void {
            this.selectedTab = val
        },
        setShowIframe(val: boolean): void {
            this.showIframe = val
        },
        setSelectedCoin(coin: string): void {
            this.selectedCoin = coin.toUpperCase()
        },
        setCoinSupported(coinSupported: any): void {
            this.coinSupported = coinSupported
        },
        setBalanceState(val: any): void {
            this.balance_selected = val
        },
        setFilteredProperty(val: string): void {
            this.filteredProperty = val
        },
        setSelectedGT(val: string): void {
            this.selectedGT = val
        },
        setSelectedPV(val: string): void {
            this.selectedPV = val
        },
        setSideCat(str: string): void {
            this.sideCat = str
        },
        setEthMultiplier(val: number): void {
            this.ethMultiplier = val
        },

        setIndividualBoolean(val: boolean): void {
            this.individualLoading = val
        },

        setStatistics(val: any): void {
            this.statistics = val
        },
        setLoadingModal(val: boolean): void {
            this.loading_modal = val
        },

        setCurrency(val: string): void {
            this.currency = val
        },
        setAmount(val: number): void {
            this.amount = val
        },
        setCrypto(val: any): void {
            this.crypto = val
        },
        setCat(cat: number) {
            this.category = cat
        },
        setErrors(err: string): void {
            this.errors[0] = err
        },
        setSideGame(val: boolean): void {
            this.sideGameOpened = val
        },
        setShowchat(val: boolean): void {
            this.showChat = val
        },

        setPageDashboard(val: number): void {
            ;(this.pageDashboard = val), (this.pageDashboardReal = val)
        },
        plusPageDashboard(val: number): void {
            this.pageDashboard += val
            this.pageDashboardReal += val
        },
        setSearchList(val: any): void {
            this.searchList = val
        },
        setCryptoAddress(val): void {
            this.crypto_address = val
        },
        setLiveBets(val: any): void {
            this.livebets = val
            console.log('LIVEBETS', this.livebets)
        },
        setBalance(val: number, cur: string): void {
            const val_ = val.toString()
            const numb = Number(val_.slice(0, 8))
            this.balance.balance = numb
            this.balance.currency = cur
        },
        increment(): void {
            this.category++
        },

        setFilterCategory(val: string): void {
            this.filter_category = val
        },
        setGameUrl(val: string): void {
            this.gameUrl = ''
        },
        resetLinkHistory(): void {
            this.linkHistory = ['']
        },

        setHeader(header: string): void {
            this.modal.header = header
        },
        setModalOpen(val: boolean, name: string | ''): void {
            ;(this.modal.modalName = name), (this.modal.modalOpen = val)
        },
        setMultipliers(val: any): void {
            console.log('GETMUKTS', val)
            this.multipliers = val
        },
        async setGameList(list: string): Promise<void> {
            console.log('GAMELIST', list)
            this.setLoading(true)
            if (list == 'all') {
                const { data, error } = await supabase
                    .from('qt_games')
                    .select('*') // Select all columns or specific ones
                    .in('provider_id', allowedProviderIds)
                    .limit(50)

                const orderedData = data!.sort((a, b) => {
                    const indexA = providerOrder.indexOf(a.provider_id)
                    const indexB = providerOrder.indexOf(b.provider_id)

                    if (indexA === -1) return 1 // Move non-prioritized providers to the end
                    if (indexB === -1) return -1
                    return indexA - indexB // Sort by priority order
                })

                console.log('Ordered results:', orderedData)
                this.setLoading(false)

                if (error) {
                    console.error('Error fetching sorted games:', error)
                } else {
                    this.searchList = orderedData
                    this.gameList.items = orderedData
                }
            }
            if (list == 'new_games') {
                const { data, error } = await supabase
                    .from('qt_games')
                    .select('*') // Select all columns or specific ones
                    .in('provider_id', allowedProviderIds)
                    .order('release_date', { ascending: false })
                    .limit(50)
                this.setLoading(false)
                const orderedData = data!.sort((a, b) => {
                    const indexA = providerOrder.indexOf(a.provider_id)
                    const indexB = providerOrder.indexOf(b.provider_id)

                    if (indexA === -1) return 1 // Move non-prioritized providers to the end
                    if (indexB === -1) return -1
                    return indexA - indexB // Sort by priority order
                })
                if (error) {
                    console.error('Error fetching sorted games:', error)
                } else {
                    this.searchList = orderedData
                    this.gameList.items = orderedData
                }
            }
            if (list == 'slot_games') {
                this.setLoading(true)

                const { data, error } = await supabase
                    .from('qt_games')
                    .select('*') // Select all columns or specific ones
                    .ilike('category', '%SLOT%')
                    .in('provider_id', allowedProviderIds)
                    .limit(20)
                this.setLoading(false)
                const orderedData = data!.sort((a, b) => {
                    const indexA = providerOrder.indexOf(a.provider_id)
                    const indexB = providerOrder.indexOf(b.provider_id)

                    if (indexA === -1) return 1 // Move non-prioritized providers to the end
                    if (indexB === -1) return -1
                    return indexA - indexB // Sort by priority order
                })
                if (error) {
                    console.error('Error fetching games with matching category:', error)
                } else {
                    this.searchList = orderedData
                    this.gameList.items = orderedData
                }
            }
            if (list == 'table_games') {
                this.setLoading(true)

                const { data, error } = await supabase
                    .from('qt_games')
                    .select('*') // Select all columns or specific ones
                    .ilike('category', '%TABLEGAME%')
                    .in('provider_id', allowedProviderIds)
                    .limit(50)
                this.setLoading(false)
                const orderedData = data!.sort((a, b) => {
                    const indexA = providerOrder.indexOf(a.provider_id)
                    const indexB = providerOrder.indexOf(b.provider_id)

                    if (indexA === -1) return 1 // Move non-prioritized providers to the end
                    if (indexB === -1) return -1
                    return indexA - indexB // Sort by priority order
                })
                if (error) {
                    console.error('Error fetching games with matching category:', error)
                } else {
                    this.searchList = orderedData
                    this.gameList.items = orderedData
                }
            }
            if (list == 'live_casino') {
                this.setLoading(true)

                const { data, error } = await supabase
                    .from('qt_games')
                    .select('*') // Select all columns or specific ones
                    .ilike('category', '%LIVECASINO%')
                    .in('provider_id', allowedProviderIds)
                    .limit(50)
                this.setLoading(false)
                const orderedData = data!.sort((a, b) => {
                    const indexA = providerOrder.indexOf(a.provider_id)
                    const indexB = providerOrder.indexOf(b.provider_id)

                    if (indexA === -1) return 1 // Move non-prioritized providers to the end
                    if (indexB === -1) return -1
                    return indexA - indexB // Sort by priority order
                })
                if (error) {
                    console.error('Error fetching games with matching category:', error)
                } else {
                    this.searchList = orderedData
                    this.gameList.items = orderedData
                }
            }
            if (list == 'bonus_games') {
                this.setLoading(true)

                const { data, error } = await supabase
                    .from('qt_games')
                    .select('*')
                    .in('provider_id', allowedProviderIds)

                    .not('features', 'is', null)
                    .limit(100) // Exclude NULL values
                this.setLoading(false)

                const data_ = data?.filter(
                    item =>
                        item.features &&
                        item.features.some((feature: any) => feature.id == 'BONUS_BUY')
                )
                const orderedData = data_!.sort((a, b) => {
                    const indexA = providerOrder.indexOf(a.provider_id)
                    const indexB = providerOrder.indexOf(b.provider_id)

                    if (indexA === -1) return 1 // Move non-prioritized providers to the end
                    if (indexB === -1) return -1
                    return indexA - indexB // Sort by priority order
                })
                if (error) {
                    console.error('Error fetching games with BONUS_BUY feature:', error)
                } else {
                    this.searchList = orderedData as any[]
                    this.gameList.items = orderedData as any[]
                }
            }
            if (list == 'top_games') {
                this.setLoading(true)
                const ids = this.popularGames.map(item => item.id)
                const { data, error } = await supabase
                    .from('qt_games')
                    .select('*')
                    .in('id', ids)

                    .not('features', 'is', null) // Exclude NULL values
                this.setLoading(false)

                console.log('IDS', ids, data)
                const popGames = data?.filter(game => ids.includes(game.id))
                console.log('POPGAMES', popGames)
                const orderedData = popGames!.sort((a, b) => {
                    const indexA = providerOrder.indexOf(a.provider_id)
                    const indexB = providerOrder.indexOf(b.provider_id)

                    if (indexA === -1) return 1 // Move non-prioritized providers to the end
                    if (indexB === -1) return -1
                    return indexA - indexB // Sort by priority order
                })
                console.log('ORDEREDDAta', orderedData)
                this.searchList = orderedData as any
                this.gameList.items = orderedData as any
            }
        },

        setSideMenuList(list: any): void {
            this.sideMenuList.items = list
        },
        addItemSide(item: any): void {
            addUniqueObjects(this.sideMenuList.items, item)
        },
        setGameList2(list: any): void {
            this.gameList.items = list
        },
        setLinkSide(link: string): void {
            this.nextLinkSide = link
        },
        setLink(link: string): void {
            this.nextLink = link
        },

        async getLastPlayed() {
            const urlLink = 'https://uno.thegamehub.gg:3009/getlastplayed'
            const token = await auth.currentUser!.getIdToken()

            await axios({
                method: 'get',
                headers: {
                    sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x',
                    'user-token': token
                },
                url: urlLink
            }).then((res: any) => {
                this.setLastPlayed(res.data)
            })
        },
        async getGameList(
            params: any,
            url: string,
            component: string,
            pagination = null
        ): Promise<void> {
            this.popular = false

            this.prevLink = this.currentLink
            this.currentLink = this.nextLink
            this.isLoading = true
            let urlLink = ''

            urlLink = `https://uno.thegamehub.gg:3009/gamelist${
                params.size ? '?size=' + (this.category == 5 ? '50' : params.size) : ''
            }${params.cursor ? '&cursor=' + params.cursor : ''}${
                params.page ? '&page=' + params.page : ''
            }${params.gameTypes ? '&gameTypes=' + params.gameTypes : ''}${
                params.sortBy ? '&sortBy=' + params.sortBy : ''
            }${
                params.orderBy ? '&orderBy=' + params.orderBy : ''
            }&type=logo-square&height=200&width=100`

            if (params && params.gameTypes == undefined) {
                params.gameTypes = ''
            }
            await axios({
                method: 'get',
                headers: {
                    sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x'
                },
                url: urlLink
            })
                .then((res: any) => {
                    this.totalCount = res.data.totalCount
                    this.isLoading = false
                    this.gameList = res.data
                    if (res.data.links.length > 0) {
                        this.hasNext = true
                    } else {
                        this.hasNext = false
                    }
                    if (component == 'dashboard') {
                        if (
                            this.gameList.items &&
                            this.currentGame == params.gameTypes &&
                            this.pageDashboard != 0
                        ) {
                            //think this later
                            this.gameList = res.data
                            if (pagination == 'next') {
                                this.pageDashboardReal++
                            } else if (pagination == 'prev') {
                                this.pageDashboardReal--
                            }

                            this.currentGame = params && params.gameTypes ? params.gameTypes : ''
                        } else {
                            this.gameList = res.data

                            // beg
                            if (this.pageDashboard == 0 && pagination == 'prev')
                                this.pageDashboardReal--
                            const nextUrl = this.linkHistory[this.pageDashboard]

                            // end
                            this.currentGame = params && params.gameTypes ? params.gameTypes : ''
                        }
                    } else if (component == 'sidebar') {
                        if (res.data.items.length != 0) {
                            this.sideMenuList = res.data
                            this.nextLinkSide = res.data.links[0].href
                        }
                    }
                    if (res.data.links.length > 0) {
                        if (!this.linkHistory.includes(res.data.links[0].href))
                            this.linkHistory.push(res.data.links[0].href)
                    }

                    // Set the next link from the response
                })
                .catch(error => {
                    console.error('Error fetching game list:', error) // Log any errors
                })
        },
        async getEthMultiplier(coin: any) {
            const baseDataRef = doc(db, 'aBase/baseInfo')
            const baseData = await getDoc(baseDataRef)
            const mults: any = []
            const coins = (await baseData.data()?.coinSupported) || {} // Assign the fetched value to coinSupported
            const customOrder = ['ETH', 'SOL', 'USDT', 'TON', 'USDC']
            coins.sort((a: any, b: any) => {
                const indexA = customOrder.indexOf(a.symbol)
                const indexB = customOrder.indexOf(b.symbol)

                // If the name is not in the customOrder array, it will be placed at the end
                return indexA - indexB
            })
            coins.shift()
            console.log('COINA', coins)
            coins.map(async (coin: any, index: any) => {
                try {
                    const link = `https://tonapi.io/v2/rates?tokens=${coin.symbol}&currencies=usd`
                    const response = await axios({
                        method: 'get',

                        url: link
                    })

                    mults.push({
                        [coin.symbol]:
                            response.data.rates[coin.symbol.toLocaleUpperCase()].prices.USD
                    })
                } catch (err) {
                    console.log(err)
                }
            })
            console.log('COINA m', mults)
            if (this.multipliers.length == 0) {
                this.setMultipliers(mults)
            }
            console.log('GETMULTS', mults)
            //this.setEthMultiplier(response.data.rates[coin.toLocaleUpperCase()].prices.USD)
            //return response.data.rates[coin.toLocaleUpperCase()].prices.USD
        },

        async getStatistics() {
            const currentDate = new Date()
            const formattedDate = currentDate.toISOString().slice(0, 19)
            const urlLink = `https://uno.thegamehub.gg:3009/ngr`
            const token = await auth.currentUser!.getIdToken()
            const response = await axios({
                method: 'get',
                headers: {
                    'user-token': token,
                    sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x'
                },
                url: urlLink
            })
            this.setStatistics(response) // Update only if there is a change
        },
        async getGameTransactions() {
            const urlLink = 'https://uno.thegamehub.gg:3009/lasttransaction'
            const response = await axios({
                method: 'get',
                headers: {
                    sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x'
                },
                url: urlLink
            })
            // Compare with previous transactions before setting new ones
            if (JSON.stringify(this.previousbets) !== JSON.stringify(response)) {
                const livebets = response.data.items.sort(
                    (a: any, b: any) =>
                        new Date(b.created).getTime() - new Date(a.created).getTime()
                )
                this.setLiveBets(livebets) // Update only if there is a change
                console.log('LIVEBETs', livebets)
                this.previousbets = JSON.parse(JSON.stringify(response)) // Deep copy to avoid reference issues
            }
        },
        // Method to navigate to the next page
        async getNextGameList(params_: any, component: string, cat: number): Promise<void> {
            this.pageDashboard++
            const nextUrl = this.linkHistory[this.pageDashboard]
            let result = null
            const url = nextUrl
            const queryString = url.split('?')[1]
            const param = new URLSearchParams(queryString)
            result = { cursor: param.get('cursor') }

            if (this.category == 1) {
                const param = new URLSearchParams(queryString)
                result = { page: param.get('page') }
            }

            const params = { ...result, size: 14 }
            // Update prevLink to current before navigating
            if (cat == 1) {
                await this.getMostPopularList({ ...params_, ...params }, '', component, 'next')
            } else await this.getGameList({ ...params_, ...params }, '', component, 'next')
        },

        // Method to navigate to the previous page
        async getPrevGameList(params_: any, component: string, cat: number): Promise<void> {
            this.pageDashboard--

            const prevUrl =
                this.linkHistory[this.pageDashboard] != ''
                    ? this.linkHistory[this.pageDashboard]
                    : ''

            const url = prevUrl
            const queryString = url.split('?')[1]
            const param = new URLSearchParams(queryString)
            const result = param.get('cursor')

            const params = { size: 14, cursor: result }
            if (cat == 1) {
                await this.getMostPopularList({ ...params_, ...params }, '', component, 'prev')
            } else await this.getGameList({ ...params_, ...params }, '', component, 'prev')
        },

        async getMostPopularList(
            params: any,
            url: string,
            component: string,
            pagination: string
        ): Promise<void> {
            this.popular = true
            if (params && params.gameTypes == undefined) {
                params.gameTypes = ''
            }
            await axios({
                method: 'get',
                headers: {
                    sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x'
                },
                url: `https://uno.thegamehub.gg:3009/gamepopular?size=50`
            }).then((res: any) => {
                if (component == 'dashboard') {
                    if (this.gameList.items && this.pageDashboard != 0) {
                        this.popularGames = res.data.items
                        if (pagination == 'next') {
                            this.pageDashboardReal++
                        } else if (pagination == 'prev') {
                            this.pageDashboardReal--
                        }

                        this.currentGame = params && params.gameTypes ? params.gameTypes : ''
                    } else {
                        this.popularGames = res.data.items

                        // beg
                        if (this.pageDashboard == 0 && pagination == 'prev')
                            this.pageDashboardReal--
                        const nextUrl = this.linkHistory[this.pageDashboard]

                        // end
                        this.currentGame = params && params.gameTypes ? params.gameTypes : ''
                    }
                    this.nextLink = res.data.links[0]
                } else if (component == 'sidebar') {
                    this.sideMenuList = res.data
                    this.nextLink = res.data.links[0].href
                }
                if (res.data.links.length > 0) {
                    if (!this.linkHistory.includes(res.data.links[0].href))
                        this.linkHistory.push(res.data.links[0].href)
                }
            })
        },

        async getBalance(): Promise<void> {
            try {
                const response = await fetch(
                    'https://html5-gaming-bot.web.app/qtcasino/accounts/OWKmKuByBpbb1PrYkccNMeMYCFF3/balance',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x',
                            'pass-key': '63yehdfd5373gdjssgw72u2bds72h',
                            'accept-language': 'en_EN'
                        }
                    }
                )

                const responseBody = await response.text()

                if (response.ok) {
                    const data = JSON.parse(responseBody)
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`)
                }
            } catch (error) {
                console.error('Error fetching game URL:', error)
            }
        },

        async selectGameCurrency(currencyId: string): Promise<void> {
            const token: any = await auth.currentUser?.getIdToken()

            if (token) {
                try {
                    const response = await fetch(
                        'https://uno.thegamehub.gg:3009/setSelectedCurrency',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x',
                                'user-token': token
                            },
                            body: JSON.stringify({
                                currency: currencyId.toLocaleLowerCase()
                            })
                        }
                    )

                    const contentType = response.headers.get('content-type')
                    const responseBody =
                        contentType && contentType.includes('application/json')
                            ? await response.json() // Already parsed as JSON if it's JSON
                            : await response.text() // Plain text response otherwise

                    if (response.ok) {
                        // Use responseBody directly, no need to parse it again
                        const data = typeof responseBody === 'string' ? {} : responseBody
                        const playerUid = auth.currentUser?.uid // Get the UID of the authenticated user
                        const userRef = doc(db, `users/${playerUid}`) // 'db' should be your Firestore instance
                        const userData = await getDoc(userRef)
                        const qtSelect = userData.data()?.qtSelectedCurrency
                        this.setSelectedCoin(qtSelect)
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`)
                    }
                } catch (error) {
                    console.error('Error fetching game URL:', error)
                }
            } else {
                console.log('Token must exist')
            }
        },
        async getGameUrl(id: string): Promise<void> {
            const uid = auth.currentUser?.uid
            this.setLoading(true)
            const token = await auth.currentUser?.getIdToken()
            if (token && auth.currentUser?.uid) {
                console.log('DATA', uid, token, id)
                try {
                    console.log('RESPONSE')
                    const response = await fetch('https://uno.thegamehub.gg:3009/getgameurl', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x'
                        },
                        body: JSON.stringify({
                            gameID: id,
                            uid: uid,
                            userToken: token ? token : null,
                            hideSplash: true,
                            mode: 'real',
                            device: 'mobile'
                        })
                    })
                    console.log('RESPONSE', response)
                    const responseBody = await response.text()
                    if (response.ok) {
                        const data = JSON.parse(responseBody)
                        this.setLoading(false)
                        this.gameUrl = data.url
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`)
                    }
                } catch (error) {
                    alert(error)
                }
            } else {
                alert('Login first to play any game')
            }
        }
    }
})
