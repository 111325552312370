import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import DashboardVue from '@/components/DashboardVue.vue'
import ReferAndEarnVue from '@/components/ReferAndEarnVue.vue'
import TournamentsVue from '@/components/TournamentsVue.vue'
import FaqVue from '@/components/FaqVue.vue'
import PromotionsVue from '@/components/PromotionsVue.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: DashboardVue
            },
            {
                path: 'refer',
                name: 'ReferAndEarn',
                component: ReferAndEarnVue
            },
            {
                path: 'tournaments',
                name: 'Tournaments',
                component: TournamentsVue
            },
            {
                path: 'faqs',
                name: 'Faqs',
                component: FaqVue
            },
            {
                path: 'promotions',
                name: 'Promotions',
                component: PromotionsVue
            }
        ]
    }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
