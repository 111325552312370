<template>
    <div class="flex flex-col justify-between">
        <div class="flex justify-between h-14 w-full bg-chat-header">
            <div class="flex items-center pl-3">
                <img src="../assets/chat.png" alt="" /><span
                    class="font-righteous pl-2 text-white text-base"
                    >Chat</span
                >
            </div>

            <div class="flex items-center pr-3">
                <div
                    style="width: 5px; height: 5px"
                    class="rounded-full mr-1 bg-gradient-to-b from-deposit-button-1 to-deposit-button-2"
                ></div>
                <div>
                    <span
                        class="text-xs pr-1 font-righteous bg-gradient-to-b from-deposit-button-1 to-deposit-button-2 bg-clip-text text-transparent"
                        >124 online</span
                    >
                </div>
                <button class="pl-3" @click="setShowChat(false)">
                    <img src="../assets/circle-sign.png" alt="" />
                </button>
            </div>
        </div>

        <div
            style="height: 76vh"
            class="relative flex-grow overflow-y-scroll no-scrollbar bg-chat-bg"
        >
            <div class="sticky-shadow"></div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
            <div
                class="flex items-center mb-2 mx-3 py-3 bg-chat-bubble-bg border-chat-bubble-border rounded-2xl"
            >
                <img class="pl-3" src="../assets/avatar.png" />
                <div class="block max-h-24 pl-2">
                    <div class="font-roboto flex max-h-5 justify-between items-center">
                        <div style="color: #424d82; font-size: 14px">Iluminate</div>
                        <div class="pr-2" style="color: #2a345d; font-size: 11px">09:08</div>
                    </div>
                    <div
                        style="font-size: 14px; line-height: 18px"
                        class="font-roboto text-dropdown-1-text-color flex text-start pr-1 justify-start"
                    >
                        Damn vip codes are getting snatched up quickly now
                    </div>
                </div>
            </div>
        </div>

        <div class="flex bg-chat-header">
            <div class="w-full h-full px-3 py-3 input-container">
                <input
                    type="text"
                    style="color: #2a345d"
                    class="bg-chat-bg border leading-10 border-chat-text-border text-sm rounded-xl block w-full p-2.5"
                    placeholder="Your Message"
                    required
                />
                <div class="button-container">
                    <img src="../assets/smile.png" alt="" />
                    <button class="rounded-md ml-3 w-8 h-8 mr-4 bubble-border">
                        <div
                            class="flex rounded-md items-center justify-center bubble-inner h-full"
                        >
                            <img style="height: 14px" src="../assets/send.svg" />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardStore } from '@/store/dashboardStore'
import { ref } from 'vue'
const dashboardStore = useDashboardStore()
const isOn = ref(false)

const setShowChat = val => {
    dashboardStore.setShowchat(val)
}
</script>

<style scoped>
.sticky-shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px; /* Height to cover top chat bubble */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent); /* Softer shadow */
    pointer-events: none;
    z-index: 10; /* Ensure the pseudo-element is above the chat content */
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.input-container {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

input:checked + .slider {
    border-color: red;
    background: linear-gradient(180deg, rgba(255, 239, 3, 0.7) 0%, rgba(211, 117, 30, 0.7) 100%);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 29px;
}
.input-container input[type='text'] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
}

.input-container .button-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.input-container .button-container button {
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.bubble-border {
    padding: 2px;
    border-radius: 22px;
    background: linear-gradient(180deg, #4af3ff 0%, #1d99a2 100%);
}

.bubble-inner {
    background: linear-gradient(180deg, #00e6f6 0%, #08777e 100%);
    border-radius: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
