<template>
    <div class="text-primary w-full">
        <div class="overflow-y-auto no-scrollbar h-screen outerbox rounded-l-xl">
            <div class="px-14 pt-9">
                <div class="w-full gradient-box rounded-lg banner-border">
                    <div
                        class="relative inner-box overflow-hidden inner-stuff rounded-lg banner-inner grid grid-cols-2"
                    >
                        <img
                            class="absolute z-0"
                            style="bottom: -1px"
                            src="../assets/blue-light.png"
                            alt=""
                        />
                        <img
                            class="absolute z-0"
                            style="top: 0px; right: 0px"
                            src="../assets/blue-light-2.png"
                            alt=""
                        />
                        <div class="flex-col pl-12">
                            <div>
                                <p class="text-start pt-12 pb-3 text-[32px] font-proxima font-bold">
                                    Refer Friends & Earn Rewards
                                </p>
                            </div>
                            <div>
                                <p
                                    class="text-[20px] w-[492px] text-gray-400 font-bold font-proxima text-left"
                                >
                                    Invite your friends to the Game Hub Casino and earn a percentage
                                    from every bet they make. Simply share your refferal link below.
                                </p>
                            </div>
                            <div class="grid grid-cols-[283px_1fr] gap-2 pt-8 pb-12">
                                <div class="w-full flex justify-start">
                                    <button
                                        style="border: 2px solid #ffffff4d; background: #ffffff33"
                                        class="rounded-xl w-72 z-10"
                                    >
                                        <div class="flex font-proxima">
                                            <div class="flex flex-col text-[22px] font-bold">
                                                <p class="pl-8 pt-6 text-start">Share your Link</p>
                                                <p
                                                    class="pl-8 text-[15px] pb-6"
                                                    style="color: #ffffffb2"
                                                >
                                                    https://decdfgrg.games/r...
                                                </p>
                                            </div>
                                            <div class="flex items-center pl-4 pr-4">
                                                <img src="../assets/link-icon.png" alt="" />
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div
                                    style="color: #ffffff99"
                                    class="font-proxima text-2xl font-bold flex items-center justify-start pl-10"
                                >
                                    Learn More
                                </div>
                            </div>
                        </div>
                        <div class="h-full flex justify-center">
                            <div class="w-96 relative">
                                <img
                                    class="absolute"
                                    style="top: 19px; right: -20px"
                                    src="../assets/gift4-1.png"
                                    alt=""
                                />

                                <img
                                    class="absolute"
                                    style="right: 19px; top: 39px"
                                    src="../assets/gift2-1.png"
                                    alt=""
                                />
                                <img
                                    style="top: 57px; left: 73px"
                                    class="absolute"
                                    src="../assets/star-2.png"
                                    alt=""
                                /><img
                                    style="top: 23px; left: 118px"
                                    class="absolute"
                                    src="../assets/star-1.png"
                                    alt=""
                                /><img
                                    style="top: 23px; left: 73px"
                                    class="absolute"
                                    src="../assets/star-3.png"
                                    alt=""
                                />
                                <img
                                    style="bottom: 57px; right: 79px"
                                    class="absolute"
                                    src="../assets/star-4.png"
                                    alt=""
                                />
                                <img
                                    style="bottom: 60px; right: 59px"
                                    class="absolute"
                                    src="../assets/star-5.png"
                                    alt=""
                                />
                                <img
                                    style="bottom: 97px; right: 52px"
                                    class="absolute"
                                    src="../assets/star-6.png"
                                    alt=""
                                />
                                <img
                                    style="bottom: 120px; right: 18px"
                                    class="absolute"
                                    src="../assets/star-5.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-3 gap-8 px-14 pt-14">
                <div>
                    <div class="flex flex-col text-white font-proxima font-bold">
                        <div
                            class="flex flex-col border-2 border-[#242C55] rounded-2xl justify-center items-center"
                        >
                            <div class="text-2xl py-8">ETH Referral Rewards</div>
                            <div class="flex justify-center items-center text-[40px]">
                                <span class="pr-2">0</span>
                                <img src="../assets/eth-icon.png" alt="" />
                            </div>
                            <div class="text-2xl pt-3" style="color: #ffffff99">€ 0.00</div>
                            <div class="w-full px-14 pt-10 pb-8">
                                <button
                                    class="bg-[#1C2242] rounded-xl py-4 w-full border-[#242C55] border-2"
                                >
                                    Claim 0 $ETH
                                </button>
                            </div>
                        </div>
                        <div class="flex justify-center items-center pt-4">
                            <span style="color: #ffffff4d" class="pr-2">All Time Earnings: 0</span
                            ><img class="h-5" src="../assets/eth-icon.png" alt="" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex flex-col text-white font-proxima font-bold">
                        <div
                            class="flex flex-col border-2 border-[#242C55] rounded-2xl justify-center items-center"
                        >
                            <div class="text-2xl py-8">GHUB Referral Rewards</div>
                            <div class="flex justify-center items-center text-[40px]">
                                <span class="pr-2">0</span>
                                <img class="h-14" src="../assets/g-hub.png" alt="" />
                            </div>
                            <div class="text-2xl pt-3" style="color: #ffffff99">€ 0.00</div>
                            <div class="w-full px-14 pt-10 pb-8">
                                <button
                                    class="bg-[#1C2242] rounded-xl py-4 w-full border-[#242C55] border-2"
                                >
                                    Claim 0 $GHUB
                                </button>
                            </div>
                        </div>
                        <div class="flex justify-center items-center pt-4">
                            <span style="color: #ffffff4d" class="pr-2">All Time Earnings: 0</span
                            ><img class="h-5" src="../assets/g-hub.png" alt="" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex flex-col text-white font-proxima font-bold">
                        <div
                            class="flex flex-col border-2 border-[#242C55] rounded-2xl justify-center items-center"
                        >
                            <div class="text-2xl py-8">USDT Referral Rewards</div>
                            <div class="flex justify-center items-center text-[40px]">
                                <span class="pr-2">0</span>
                                <img src="../assets/usdt-icon.png" alt="" />
                            </div>
                            <div class="text-2xl pt-3" style="color: #ffffff99">€ 0.00</div>
                            <div class="w-full px-14 pt-10 pb-8">
                                <button
                                    class="bg-[#1C2242] rounded-xl py-4 w-full border-[#242C55] border-2"
                                >
                                    Claim 0 $USDT
                                </button>
                            </div>
                        </div>
                        <div class="flex justify-center items-center pt-4">
                            <span style="color: #ffffff4d" class="pr-2">All Time Earnings: 0</span
                            ><img class="h-5" src="../assets/usdt-icon.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup></script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.gradient-box {
    border-radius: 20px; /* Adjust this value to change the border radius */
    padding: 1px; /* Adjust to match the border width */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-box {
    width: 100%;
    height: 100%;
    border-radius: 18px; /* Slightly less than the outer box to fit inside */

    color: white;
    font-size: 1.5rem;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.banner-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #1d58ca 100%);
}

.banner-inner {
    background: linear-gradient(180deg, #1a154d 19.44%, #044e66 100%);
}

.outerbox {
    border-color: theme('colors.border-dropdown-1');
    background: theme('colors.dropdown-1');
}
</style>
