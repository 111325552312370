<template>
    <div class="loading-page2">
        <img width="250" height="250" src="../assets/loader.gif" alt="">
    </div>
</template>

<script>
export default {
    name: 'LoadingVue',
};
</script>

<style scoped>
.loading-page2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 11, 49);
    z-index: 9999;
}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>