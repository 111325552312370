<template>
    <div>

        <div class="flex h-screen bg-screen overflow-hidden">

            <button @click="handleCl"></button>
            <div class="shrink w-full h-screen ">
                <div class="grid grid-rows-[auto_1fr] h-full">
                    <div style="margin-bottom: 20px;" class="row-1">

                        <NavBarVue :key="dashboardStore.componentKey" :coinSupported="coinSupported"
                            :balance="balance_" />
                    </div>
                    <div style="margin-bottom:55px; background: #151A32;" class="overflow-y-auto flex      ">
                        <div class="shrink w-full ">
                            <router-view />
                        </div>
                        <div v-if="dashboardStore.showChat == true" class="flex justify-between sticky top-0 min-w-80">
                            <ChatVue />
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="iframeOpened && showIframe">
                <iframe class="absolute left-0 top-0 h-[100vh] w-[100vw] z-50" :src="dashboardStore.gameUrl"
                    frameborder="0"></iframe>
                <button style="
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    color: white;
    border: none;
    padding: 5px 10px;
    color:black;
    cursor: pointer;
    border-radius:10px;
    font-size: 16px;
    z-index: 1000;
  " @click="closeIframe">Close</button>
            </div> -->
        </div>
    </div>
</template>

<script setup>
import ChatVue from '@/components/ChatVue.vue'
import NavBarVue from '../components/NavBarVue.vue'
import SideBarVue from '../components/SideBarVue.vue'
import { useDashboardStore } from '@/store/dashboardStore'
import { auth, balance, coinSupported, data, componentKey } from '@/main'
import { ref, computed, onMounted, watch } from 'vue'

// Use the store directly in the script setup block
const dashboardStore = useDashboardStore()
const iframeOpened = ref(false)
const showIframe = ref(true);

const closeIframe = () => {
    showIframe.value = false;
};
const openIframe = item => {
    dashboardStore.getGameUrl(item.id)
    if (!iframeOpened.value) {
        iframeOpened.value = true
    }
}

const coins = ref(null)
const balance_ = ref(null)
const handleCl = () => {
    console.log(coinSupported)
}
onMounted(() => {
    setTimeout(() => {
        coinSupported_.value = coinSupported
        coins.value = coinSupported
        balance_.value = balance
    }, 2000); // Delay for example purposes
})

const coinSupported_ = computed(() => {
    return coinSupported
})


watch(
    () => coinSupported,
    (newValue, oldValue) => {
        console.log('sharedVariable changed:', oldValue, '->', newValue);
        // Handle the change here
    }
);

</script>

<style scoped>
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.row-1 {
    height: 70px;
}

.loading-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    /* light overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* make sure it covers everything */
}

/* Style for the loader (you can customize this) */
.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

</style>
