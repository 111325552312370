<template>
    <div class="w-full">
        <span class="text-[27px] font-bold pt-3  text-bold ">Deposit {{ dashboardStore.crypto.name }}
        </span>
        <div class="text-start pt-3 text-base pb-5 ">Address:
            <input type="text" style="all: unset" id="myInput" :value="dashboardStore.crypto_address.data.address"
                readonly />
            <button class=" px-2  rounded-lg py-1 border-profile-deposit text-sm bg-profile-deposit border-[2px]"
                @click="myFunction">Copy
                Me</button>
        </div>
        <div class="text-start pt-3 text-base pb-5 ">Minimum Deposit:
            {{ dashboardStore.crypto_address.data.minimum }} {{ dashboardStore.crypto.symbol }}</div>
        <div class="text-start pt-3 text-base  pb-5 ">Valid Date:
            {{ moment(dashboardStore.crypto_address.data.valid_until).format('YYYY-MM-DD') }}</div>

        <div class="flex justify-center pb-8 pt-3">

            <Qrcode size="150" :value="qrText" />
        </div>

    </div>
</template>

<script setup>
import axios from 'axios'

import Qrcode from 'qrcode.vue'
import ButtonBox from '../form/ButtonBox.vue'
import moment from 'moment'

import {
    computed

} from 'vue'


import { useDashboardStore } from '@/store/dashboardStore'


import { useAuthStore } from '@/store/authStore'

const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const setModal = (val, name) => {
    dashboardStore.setModalOpen(val, name)
}

const qrText = computed(() => {
    return dashboardStore.amount ?
        `${dashboardStore.crypto.name}:
    ${dashboardStore.crypto_address.address}
    ?amount=${dashboardStore.amount}` : `
    ${dashboardStore.crypto.name}:
    ${dashboardStore.crypto_address.address}`
})

function myFunction() {
    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    alert("Copied the text: " + copyText.value);
}


</script>

<style scoped>
/* Custom checkbox tick color */
input[type='checkbox']:checked {
    background-color: #131931;
    /* Green background when checked */
    border-color: #1e284a;
    /* Green border when checked */
    box-shadow: 0px 12px 16px 0px #0000000f;
}

input[type='checkbox']:disabled:focus {
    box-shadow: none;
    border-color: #1e284a;
    /* Green border when checked */
}

input[type='checkbox']:focus {
    box-shadow: none !important;
}

.custom-checkbox input[type='checkbox'] {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #131931;
    border: 2px solid #1e284a;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.custom-checkbox input[type='checkbox']:checked {
    border-color: #1e284a;
    /* Green border when checked */
}

.custom-checkbox input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 39%;
    left: 47%;
    transform: translate(-50%, -50%) rotate(44deg) scaleX(1);
    width: 6px;
    height: 11px;
    border: solid #00ff94;
    box-shadow: 0px 0px 0px 0px #00ff9473;
    border-width: 0 3px 3px 0;
}

.custom-checkbox label {
    font-size: 11px;
    padding-left: 5px;
}

/* Hide default checkbox appearance and style the new one */
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.5rem;
    accent-color: #4caf50;
    /* Change tick color */

    height: 1.5rem;
    background-color: #131931;
    /* Green background when checked */
    border-radius: 0.375rem;
    /* Tailwind rounded */
    border-color: #4caf50;
    /* Green border when checked */
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;
}
</style>
