<template>
    <div class="text-primary w-full">
        <div class="overflow-y-auto no-scrollbar h-screen outerbox rounded-l-xl">
            <div class="grid px-14 pt-9">
                <CarrouselVue :images="images" />
                <div class="flex pt-6 justify-start items-center text-xl font-righteous pb-2">
                    <img class="max-h-5 pr-1" src="../assets/prom-icon.png" />
                    <span class="text-white pr-1">Ongoing </span
                    ><span class="text-[#00e6f6]">Promotions</span>
                </div>
                <div class="grid grid-cols-5 gap-x-2 gap-y-6 pb-6">
                    <div class="image-border"><img src="../assets/img-2.png" /></div>
                    <div class="image-border flex justify-center">
                        <img src="../assets/img-3.png" />
                    </div>
                    <div class="image-border flex justify-center">
                        <img src="../assets/img-4.png" />
                    </div>
                    <div class="image-border flex justify-center">
                        <img src="../assets/img-5.png" />
                    </div>
                    <div class="image-border flex justify-center">
                        <img src="../assets/img-2.png" />
                    </div>
                    <div class="image-border"><img src="../assets/img-1.png" /></div>
                    <div class="image-border flex justify-center">
                        <img src="../assets/img-2.png" />
                    </div>
                    <div class="image-border flex justify-center">
                        <img src="../assets/img-4.png" />
                    </div>
                    <div class="image-border flex justify-center">
                        <img src="../assets/img-5.png" />
                    </div>
                    <div class="image-border flex justify-center">
                        <img src="../assets/img-2.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import CarrouselVue from './carrouselVue.vue'
const images = ref([
    require('../assets/referral.png'),

    require('../assets/TOPup_bonus.png'),
    require('../assets/tournaments_casino.png'),
    require('../assets/vip_rewards.png')
])
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.image-border {
    border-radius: 20px;
}

.banner-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #1d58ca 100%);
}

.banner-inner {
    background: linear-gradient(180deg, #1a154d 19.44%, #044e66 100%);
}

.box-warrior {
    background: linear-gradient(
        120deg,
        rgba(192, 112, 21, 1) 0%,
        rgba(183, 117, 95, 1) 100%,
        rgba(186, 120, 95, 1) 100%
    );

    /* mask */
}

.outerbox {
    border-color: theme('colors.border-dropdown-1');
    background: theme('colors.dropdown-1');
}
</style>
