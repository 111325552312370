import { onMounted, ref } from 'vue'



export function useLazyLoad() {
    const observer = ref()

    onMounted(() => {
        observer.value = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const img: any = entry.target
                    img.src = img.dataset.src // Load the actual image
                    observer.unobserve(img) // Stop observing once the image is loaded
                }
            })
        })

        const images = document.querySelectorAll('.lazy-load') // Select all images with the lazy-load class
        images.forEach(img => {
            observer.value.observe(img) // Start observing each image
        })
    })
}


export const addUniqueObjects = (original: any, newObjects: any) => {
    const stringifiedOriginal = original.map((item: any) => JSON.stringify(item))

    newObjects.forEach((obj: any) => {
        if (!stringifiedOriginal.includes(JSON.stringify(obj))) {
            original.push(obj)
        }
    })
}

export const arrayPos = (originalArray: any, length: number, page: number) => {
    return originalArray.slice(length * (page + 1) - length, length * (page + 1))
}

export const createArraySlice = (originalArray: any, start: number, length: number) => {
    if (originalArray && length != undefined) {
        return originalArray
    }
}

export const findMatchingItems = (previousItems: any, latestItems: any) => {
    // Create a set of IDs from the previousItems for quick lookup
    const previousItemIds = new Set(previousItems.map((item: any) => item.id))

    // Filter the latestItems to find those that have matching IDs in previousItemIds
    return latestItems.filter((item: any) => previousItemIds.has(item.id))
}
