import { defineStore } from 'pinia'

interface User {
    email: string
    pass: string
}

// Define an interface for the state
interface AuthState {
    user: User
    isAuth: any
    errors: string
    loading: boolean
}

// Define an interface for the getters
interface AuthGetters {
    isAuthenticated(state: AuthState): boolean
}

// Define an interface for the actions
interface AuthActions {
    login(): void
    logout(): void
    setErrors(val: string): void
    [key: string]: any // Allow string index signature for getters or dynamic properties
    isLoading(val: boolean): void
}

// Define the store
export const useAuthStore = defineStore<'auth', AuthState, AuthActions>('auth', {
    state: (): AuthState => ({
        isAuth: true,
        user: { email: '', pass: '' },
        errors: '',
        loading: false
    }),

    // Adding a getters section

    actions: {
        login(): void {
            this.isAuth = true
        },
        logout(): void {
            sessionStorage.clear()
            this.isAuth = false
        },

        setErrors(val: string): void {
            this.errors = val
        },
        isLoading(val: boolean) {
            this.loading = val
        }
    }
})
