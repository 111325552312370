<template>
    <header class="pt-1 bg-screen">
        <!-- <div v-if="dashboardStore.loading == true || dashboardStore.loading_modal == true" class="loading-page"
            id="loadingPage">
            <div class="loader"></div>
        </div> -->
        <div v-if="authStore.isAuth == true" class="flex justify-between items-center w-full">
            <button @click="handleHomeClick" class="flex justify-start">

                <div class="relative flex justify-center">
                    <img style="height: 91px;padding-top:10px" src="../assets/the-game-hub.png" />
                </div>


            </button>
            <div class="flex">


                <div v-if="filteredCoinBalances.length > 0" ref="componentRef" style="height: 38px; border-radius: 12px"
                    class="w-36 z-1 mr-2 font-roboto font-extrabold text-center flex justify-start items-center bg-profile-deposit border-profile-deposit">
                    <div class="flex w-[100%] text-base">

                        <span class="text-white pl-3 flex items-center"><img class="min-w-5 min-h-5" width="20"
                                height="20 " v-lazy="filteredCoinBalances[0].img" alt=""></span>
                        <button id="dropdown" @click="toggleDropdown" :disabled="dashboardStore.checkCanToggle"
                            class="flex w-full items-center group peer">
                            <span class="pl-2 text-base text-white">
                                {{ '$' + (
                                dashboardStore.multipliers.length > 0 && dashboardStore.selectedCoin
                                ? (
                                Number(
                                (
                                dashboardStore.multipliers.find(obj => obj[dashboardStore.selectedCoin] !== undefined)
                                || {}
                                )[dashboardStore.selectedCoin] || 0
                                ) *
                                Number(
                                dashboardStore.selectedCoin.toLowerCase() in balance_selected &&
                                isAllLowerCaseAlpha(dashboardStore.selectedCoin.toLowerCase())
                                ? getNestedPropertyCaseInsensitive(
                                balance_selected,
                                dashboardStore.selectedCoin.toLowerCase(),
                                'amount'
                                ) || '0.00'
                                : '0.00'
                                )
                                ).toFixed(2)
                                : '0.00'
                                )
                                }}




                            </span>
                            <div class="flex items-center  justify-end ml-auto pr-2 "><svg
                                    :class="{ 'rotate-90': isRotated }"
                                    class="w-4 h-4 flex justify-end  text-arrow-color group-open:rotate-90"
                                    xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
                                    viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                    </path>
                                </svg></div>
                        </button>


                        <ul v-if="isOpen == true && dashboardStore.multipliers.length != 0 && dashboardStore.multipliers.length != 0"
                            class="dropdown-menu text-xs">
                            <li :class="index == 0 ? 'dropdown-item rounded-t-lg' : index == coinBalances.length - 1 ? 'dropdown-item rounded-b-lg' : 'dropdown-item'"
                                v-for="(coin, index) in coinBalances" :key="index" @click="selectCoin(coin, index)">
                                <img :src="coin.img" :alt="coin.name" width="20" height="20" />
                                {{ '$' +
                                (dashboardStore.multipliers.length &&
                                dashboardStore.multipliers.find(obj => obj[coin.symbol])?.[coin.symbol] * coin.amount ||
                                0).toFixed(2)
                                }}

                            </li>
                        </ul>

                    </div>

                    <!-- <button style="width:100%" class="flex text-[21px]  h-[100%] pb-0 text-[white] pr-3 justify-end">+
                    </button> -->
                    <!--To remove-->
                </div>
                <button @click="setModal(true, 'search-modal')" style="height:40px"
                    class="mr-2 py-1 rounded-full w-12 bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2">
                    <img :src="require('../assets/icon-search.png')" alt="Icon" width="30" height="24" class="ml-2" />
                    <!-- Adjust size as needed -->
                </button>
                <button @click="setModal(true, 'profile-modal')" class="z-0 mr-4">
                    <img style="width: 50px" class="rounded-[100%] "
                        :src="auth.currentUser && auth.currentUser.photoURL" />
                </button>

            </div>
        </div>

        <div v-if="authStore.isAuth == false" class="grid grid-cols-6 gap-2 font-righteous text-xs">
            <div class="pl-8 col-start-1 col-end-3">
                <div class="flex justify-start h-9">
                    <button class="side-button md:min-w-40 relative rounded-full vip">
                        <div class="flex">
                            <img style="bottom: 37px; left: -1px" class="absolute" src="../assets/gemos.png" />
                            <img style="bottom: -23px; left: -5px" class="absolute left-0" src="../assets/gemsos.png" />
                            <div class="z-0 flex w-full pl-5 items-center justify-center">
                                <span class="text-vip-button pr-1">Vip</span>
                                <span class="text-white">Club</span>
                            </div>
                        </div>
                    </button>
                    <button class="side-button md:min-w-48 ml-3 rounded-full relative bonus">
                        <div class="flex">
                            <img style="bottom: -17px; left: -8px" class="absolute" src="../assets/presentino.png" />
                            <div class="z-0 flex w-full pl-5 items-center justify-center">
                                <span
                                    class="bg-gradient-to-b from-deposit-button-1 to-deposit-button-2 pr-1 bg-clip-text text-transparent">Deposit</span><span
                                    class="text-white">Bonus</span>
                            </div>
                        </div>
                    </button>
                </div>
            </div>

            <div class="col-end-7 col-span-2 relative">
                <!-- <img src="../assets/elipssun.png" class="absolute bottom-0 top-0" /> -->

                <div class="flex justify-end pr-4 items-center">
                    <!--test code -->

                    <button @click="setModal(true, 'search-modal', 'all')"
                        class="mr-2 py-1 rounded-full w-12 bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2">
                        <img :src="require('../assets/icon-search.png')" alt="Icon" width="30" height="24"
                            class="ml-2" />
                        <!-- Adjust size as needed -->
                    </button>

                    <button @click="setModal(true, 'profile-modal')" class="z-0">
                        <img style="width: 60px" class="pr-3" src="../assets/avatar.png" />
                    </button>
                    <button @click="setModal(true, 'deposit-modal')" style="height: 38px; border-radius: 12px"
                        class="w-44 mr-2 font-roboto font-extrabold text-center flex justify-center bg-profile-deposit border-profile-deposit">
                        <img style="height: 30px; padding-top: 5px; padding-left: 8px"
                            src="../assets/dollar.png" /><span class="text-white flex items-center pr-3">{{
                            balance.eth
                            }} </span>

                        <div style="border-radius: 12px"
                            class="w-[7rem] z-0 bg-gradient-to-b text-white from-button-yellow-1 to-button-yellow-2 px-3">
                            DEPOSIT
                        </div>
                        <!--To remove-->
                    </button>
                    <div class="pr-2">
                        <button class="rounded-md w-9 h-9 border-2 border-border-dropdown-1 bg-dropdown-1">
                            <div class="flex justify-center">
                                <img src="../assets/bell.png" />
                            </div>
                        </button>
                    </div>
                    <div>
                        <button @click="handleLogout"
                            class="rounded-md w-9 h-9 border-2 border-border-dropdown-1 bg-dropdown-1">
                            <div class="flex justify-center">
                                <img src="../assets/log-out.png" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>



        <div v-if="dashboardStore.showIframe == true && dashboardStore.individualLoading == false">

            <div style="z-index: 9998;" class="w-[100vw]  h-[100vh] bg-black fixed left-0 top-0 ">

                <iframe class="fixed left-0 top-0 h-[100vh] w-[100vw] z-50" :src="dashboardStore.gameUrl"
                    frameborder="0" :height="iframeHeight"></iframe>


            </div>
        </div>
    </header>
    <ModalData :isOpen="modalState.modalOpen" :name="modalState.modalName"
        :header="modalState.modalName == 'forget-modal' ? 'Login' : header" />
</template>

<script setup>
import ModalData from '../components/ModalData'
import { useDashboardStore } from '@/store/dashboardStore'
import { useAuthStore } from '@/store/authStore'
import { computed, ref, onMounted, watch, onBeforeUnmount, onUnmounted } from 'vue'
import { logOut, qtSelectedCurrency } from '@/main';
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

import { auth, coinSupported, db, balance } from '@/main';
import { inject, nextTick } from 'vue';
import { DashboardTwoTone } from '@material-ui/icons';

const balanceState = inject('balanceState');
const showIframe = ref(true);
const closeIframe = () => {
    showIframe.value = false;
};
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const header = ref('')
const setChatShow = val => {
    dashboardStore.setShowchat(val)

}

const iframeHeight = ref(window.innerHeight - 3);
const updateHeight = () => {
    iframeHeight.value = window.innerHeight - 3;
};




async function exitGame() {
    compKey.value = true
    console.log("COINSSS", props.coinSupported ? props.coinSupported : coinSupported)
    await dashboardStore.getEthMultiplier(props.coinSupported ? props.coinSupported : coinSupported)
    dashboardStore.setCheckToggle(true)
    dashboardStore.setLoading(true)

    dashboardStore.getLastPlayed()
    dashboardStore.setShowIframe(false)
    dashboardStore.setGameUrl('')
    await fetchUserBalance()

    dashboardStore.setCheckToggle(false)
    dashboardStore.setLoading(false)


}


function isAllLowerCaseAlpha(str) {
    return /^[a-z]+$/.test(str); // Matches only lowercase alphabetic characters
}

function hideBackButton() {
    window.parent.postMessage("hideBackButton", "*");

}

const buttonDisabled = ref(false)

const messageHandler = (event) => {
    buttonDisabled.value = true
    if (event.data === 'onClickBack') {
        dashboardStore.setCheckToggle(true)
        hideBackButton();
        if (!compKey.value) {
            exitGame();
        }
        buttonDisabled.value = false
    }
};

onMounted(() => {
    console.log('Listening for Telegram back button events...');
    window.addEventListener('message', messageHandler);
});

onUnmounted(() => {
    window.removeEventListener("resize", updateHeight);

    console.log('Cleaning up Telegram back button listener...');
    window.removeEventListener('message', messageHandler);
});
const selectedCurrency = ref(qtSelectedCurrency ? qtSelectedCurrency : 'eth')

function getNestedPropertyCaseInsensitive(obj, property, nestedKey) {
    // Normalize the outer property case
    const normalizedKey = Object.keys(obj).find(
        key => key.toLowerCase() === property.toLowerCase()
    );

    // Access the nested property if it exists
    return normalizedKey && obj[normalizedKey][nestedKey] !== undefined
        ? obj[normalizedKey][nestedKey]
        : undefined;
}

const selectedMultiplier = computed(() => {
    console.log("mults", dashboardStore.multipliers)
    // Search for the object where the key matches the token
    const found = dashboardStore.multipliers.find((item) => { Object.keys(item)[0] == dashboardStore.selectedCoin });
    console.log("FOUND", dashboardStore.multipliers, found, dashboardStore.selectedCoin)
    // Return the value of the matched key or null if not found
    return found ? found[dashboardStore.selectedCoin] : null;



});
const selected_index = ref(0)
const isOpen = ref(false);
const balance_selected = ref({ eth: { amount: '0.00' } })

const props = defineProps(['coinSupported', 'balance'])


const setModal = async (val, name) => {
    isOpen.value = false
    dashboardStore.setModalOpen(val, name)
    console.log("searchModal")
    if (name == 'register-modal') dashboardStore.setHeader('Sign Up')
    else if (name == 'login-modal' || name == 'forget-modal') dashboardStore.setHeader('Login')
    else if (name == 'profile-modal') { dashboardStore.setSelectedTab(2), dashboardStore.setHeader('Profile') }
    else if (name == 'deposit-modal') { dashboardStore.setSelectedTab(3), dashboardStore.setHeader('Deposit') }
    else if (name == 'search-modal') {
        dashboardStore.setIsTournaments(false)
        dashboardStore.setGameList('all')
        dashboardStore.setSelectedPV('')
        dashboardStore.setSelectedGT('')

        dashboardStore.setSelectedTab(1),
            dashboardStore.setHeader('Search'),

            setTimeout(() => {
                dashboardStore.setLoadingModal(true)
            }, 500)

    }
}



async function fetchUserBalance() {
    //test
    const playerUid = auth.currentUser ? auth.currentUser.uid : null
    if (playerUid) {
        const userRef = doc(db, `users/${playerUid}`)
        try {
            const userData = await getDoc(userRef)
            if (userData.exists()) {
                console.log('before init ')
                try {
                    const balances = await userData.data().balances
                    console.log('after init1', balances)
                    console.log('after init2', balances)
                    balance_selected.value = balances
                    dashboardStore.setBalanceState(balances)

                    console.log("BALANCE", balance_selected.value)
                    console.log('Real balance', balance_selected.value, qtSelectedCurrency)
                } catch {
                    console.log('User document does not exist')
                }
            } else {
                console.log('User document does not exist')
            }
        } catch (error) {
            console.error('Error fetching user document:', error)
        }
    }
}
onMounted(async () => {
    window.addEventListener("resize", updateHeight);

    await dashboardStore.getEthMultiplier(props.coinSupported ? props.coinSupported : coinSupported)

    const playerUid = auth ? auth?.currentUser?.uid : null// Get the UID of the authenticated user
    console.log("QTselect", playerUid)
    const userRef = doc(db, `users/${playerUid}`) // 'db' should be your Firestore instance

    fetchUserBalance()
    console.log("BALANCE FETCHED")

    console.log("BALANCE", qtSelectedCurrency)
    const userData = await getDoc(userRef)
    const qtSelect = await userData.data()?.qtSelectedCurrency
    console.log("QTselect", qtSelect)
    selectedCurrency.value = qtSelect
    dashboardStore.selectGameCurrency(qtSelect)
    compKey.value = false



})

const coin_selected = computed(() => {
    return dashboardStore.selectedCoin
}

)

const compKey = ref(false)

const selectedOption = ref(coin_selected.value ? coin_selected.value : 'ETH');

const selectCoin = (coin, index, value) => {
    selected_index.value = index
    console.log(coin)
    selectedOption.value = coin.symbol;
    dashboardStore.setSelectedCoin(coin.symbol)
    dashboardStore.selectGameCurrency(coin.symbol)

    isOpen.value = false;
};
const componentRef = ref(null);
const triggerReload = () => {
    dashboardStore.setComponentKey()

}

// Detect clicks outside the component
const handleClickOutside = (event) => {
    console.log(event.target, event.currentTarget, "target")
    if (event.target === 'ol') {
        console.log("EVENT", 'Directly clicked on the <ol>');
    } else {
        console.log("EVENT", 'Clicked on a child element of <ol>');
    }
    if (componentRef.value && !componentRef.value.contains(event.target)) {
        // Perform your action here
        isOpen.value = false
        isRotated.value = false
        console.log("Clicked outside the component");
    }
};
const isRotated = ref(false)
// Add event listener on mount and remove it on unmount
onMounted(() => {

    document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {

    document.removeEventListener("click", handleClickOutside);
});

const toggleDropdown = () => {
    isOpen.value = !isOpen.value;
    isRotated.value = !isRotated.value;
    console.log("BALANCE", balance_selected.value)
    console.log("open", isOpen.value)
};


function limitDecimals(number) {
    return parseFloat(number.toFixed(2));
}
const coinSupported_ = computed(() => {
    return coinSupported
})




const coinBalances = computed(() => {
    let coinSupported2
    if (Object.keys(props.coinSupported).length != 0 || props.coinSupported.length != 0) {
        console.log("1", props.coinSupported)
        coinSupported2 = props.coinSupported
    }
    if (Object.keys(coinSupported_.value).length != 0 || coinSupported_.value.length != 0) {
        console.log("2", coinSupported_.value)

        coinSupported2 = coinSupported_.value
    }
    if (Object.keys(coinSupported).length != 0 || coinSupported.length != 0) {
        console.log("3", coinSupported)
        coinSupported2 = coinSupported
    }


    if (dashboardStore.coinSupported.length != 0) {
        console.log(coinSupported, coinSupported2.length, 'COINSUPPORTED')

        return dashboardStore.coinSupported.length != 0 && dashboardStore.coinSupported.filter((coin) => coin.symbol.toLowerCase() !== "ghub") // Exclude GHUB
            .map((coin) => {
                const balance_ = balance_selected.value ? balance_selected.value[coin.symbol.toLowerCase()]?.amount ?? 0.00 : 0.00;
                console.log("balance", balance_)

                return {
                    symbol: coin.symbol,
                    name: coin.name,
                    img: coin.img,
                    amount: balance_,
                    multiplier: dashboardStore.multipliers[coin.symbol]
                };
            });
    } else return null

});

const filteredCoinBalances = computed(() => {
    console.log("COINBALANCES", coinBalances.value)
    return coinBalances.value && coinBalances.value.filter(coin =>
        coin.symbol.includes(dashboardStore.selectedCoin)
    );
});

const amount = computed(() => {
    return balance && balance[dashboardStore.selectedCoin.toLocaleLowerCase()].amount;
})

const handleHomeClick = () => {
    window.parent.postMessage("backHome", "*");
}
const handleLogout = () => {
    console.log("here")
    logOut().then(() => {
        sessionStorage.clear()
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
        authStore.logout()
    })
}

const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';  // Adjust the decimal places with toFixed
    }
    return num.toLocaleString(); // Return the number with commas for thousands
}

const modalState = computed(() => dashboardStore.modal)
</script>

<style scoped>
.vip {
    background: linear-gradient(to bottom, #182552, #1da2cc);
}

.icon {
    background: url('../assets/icon-search.png') no-repeat left;
    margin-left: 10px;
    padding-left: 33px;
}

.input-icon-2 input::placeholder {
    padding-left: 15px;
    color: #fff;
}

.input-container {
    position: relative;
    width: 230px;
}

.input-container input::placeholder {
    color: white;
}

.input-container input {
    width: 100%;
    padding: 8px 20px 8px 55px;
    /* Adjust padding to make room for icon and pipe */
    border-width: 2px;
    font-size: 16px;
    box-sizing: border-box;
    min-width: 170px;
}

.input-icon-2 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown {
    position: relative;
    display: inline-block;
}

svg {
    transition: transform 0.2s ease-in-out;
}

svg.rotate-90 {
    transform: rotate(90deg);
}
.dropdown-menu {
    display: block;
    position: absolute;
    top: 73px;
    background-color: #f9f9f9;
    min-width: 100px;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ;
}

.dropdown-item img {
    margin-right: 8px;
}

.dropdown-item:hover {
    background-color: #ddd;

}

.vip::before {
    content: '';
    background: linear-gradient(to bottom, #121b3e, #034a68);
}

.bonus {
    background: linear-gradient(to bottom, #2b2f4e, #7b5635);
}

.bonus::before {
    content: '';
    background: linear-gradient(to bottom, #121b3e, #403135);
}

.side-button::before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 30px;
}

.filter-white {
    -webkit-filter: invert(100%);
    /* Safari/Chrome */
    filter: invert(100%);
}

.loading-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    /* light overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* make sure it covers everything */
}

/* Style for the loader (you can customize this) */
.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
