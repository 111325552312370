<template>
    <!-- component -->
    <div class="fixed z-40 inset-0 flex items-center justify-center overflow-y-auto overflow-x-hidden" v-if="isOpen">
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-bg-modal opacity-75"></div>
        </div>

        <div
            class="modal-inner gradient-box w-[100vw] h-[100vh] modal-border  text-left overflow-y-auto shadow-xl transform transition-all">
            <div class="inner-box relative min-h-14 h-[full] ">
                <button @click="setModal(false, props.name)">
                    <img class="absolute right-5 h-4 top-5 border-[#23232448] rounded-full" src="../assets/cross.svg"
                        alt="" />
                </button>

                <div class=" flex flex-col flex-start w-[100%]">
                    <p class="pt-12 pl-4 text-lg leading-6 pb-3 font-proxima font-bold text-2xl text-white text-start">
                        {{ dashboardStore.modal ? dashboardStore.modal.header : '' }}
                    </p>
                    <div style="width:500px" v-if="props.name == 'register-modal'">
                        <RegisterModal @openLoginModal="handleLoginModal" />
                    </div>
                    <div style="width:500px" v-if="props.name == 'login-modal'">
                        <LoginModal @openForgetModal="handleForgetModal" @openRegisterModal="handleRegisterModal" />
                    </div>
                    <div v-if="props.name == 'forget-modal'">
                        <ForgetModal />
                    </div>
                    <div class="px-4" v-if="props.name == 'profile-modal'">
                        <ProfileModal />
                    </div>
                    <div v-if="props.name == 'deposit-modal'">

                        <DepositModal />
                    </div>
                    <div v-if="props.name == 'payment-modal'">
                        <PaymentModal />
                    </div>
                    <div class="" v-if="props.name == 'search-modal'">
                        <SearchModal />
                    </div>
                    <div v-if="props.name == 'game-modal'">
                        <GameModal :header="dashboardStore.modal.header" :text_="text_" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardStore } from '@/store/dashboardStore'
import SearchModal from '@/components/modals/SearchModal.vue'
import RegisterModal from '@/components/modals/RegisterModal.vue'
import LoginModal from '@/components/modals/LoginModal.vue'
import ForgetModal from '@/components/modals/ForgetModal.vue'
import GameModal from './modals/GameModal.vue'
import DepositModal from './modals/DepositModal.vue'
import PaymentModal from './modals/PaymentModal.vue'
import { ref, watch } from 'vue'
import ProfileModal from '@/components/modals/ProfileModal.vue'
const props = defineProps(['isOpen', 'name', 'header', 'sidebar', 'navbar', 'text'])
const dashboardStore = useDashboardStore()
const header_val = ref(props.header)
const setModal = (val, name) => {
    if (val == false) {
        dashboardStore.setLoading(false)
        dashboardStore.setGameList([])
        console.log("loading")
    }
    dashboardStore.setErrors("")
    dashboardStore.setSelectedTab(0)
    dashboardStore.setModalOpen(val, name)
    dashboardStore.setLoading(false)

    console.log("errprs", dashboardStore.errors)
}

const handleForgetModal = () => {
    dashboardStore.setModalOpen(false, 'login-modal')
    dashboardStore.setModalOpen(true, 'forget-modal')
    dashboardStore.setHeader('Forget Password')

    header_val.value = 'Forget Password'
}

const handleLoginModal = () => {
    dashboardStore.setModalOpen(false, 'register-modal')
    dashboardStore.setModalOpen(true, 'login-modal')
    dashboardStore.setHeader('Login')
    header_val.value = 'Login'
}

const handleRegisterModal = () => {
    dashboardStore.setModalOpen(false, 'login-modal')
    dashboardStore.setModalOpen(true, 'register-modal')
    header_val.value = 'Sign Up'
    dashboardStore.setHeader('Sign Up')

}

watch(() => props.header, (newheader) => {
    header_val.value = newheader;
});

</script>

<style>
.gradient-box {
    /* Adjust this value to change the border radius */
    padding: 1px;
    /* Adjust to match the border width */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-box {
    width: 100%;
    height: 100%;

    /* Slightly less than the outer box to fit inside */
    display: flex;
    justify-content: center;
    align-items: start;
    color: white;
    font-size: 1.5rem;
    text-align: center;
}
/* Rectangle 18664 */


.modal-inner {
    background: linear-gradient(135deg, #403135, #12153e, #121b3e, #12153e, #403135);
}
</style>
