<template>
    <div class="w-[full] flex items-center mb-2 py-3 bg-chat-bubble-bg border-[2px] border-[#FFFFFF0D] rounded-lg">
        <img class="pl-3 w-16 rounded-md" :src="auth.currentUser.photoURL" />
        <div class="block max-h-24 pl-2">
            <div class="font-roboto flex max-h-5 justify-between items-center">
                <div class="text-white text-xl font-extrabold">{{ displayName }}</div>
            </div>
            <div class="pr-2 flex justify-start text-text-modal text-[13px] font-righteous">
                User Id: {{ auth.currentUser.uid }}
            </div>
        </div>

    </div>
    <div
        class="grid grid-cols-2 h-11 mt-6 items-center rounded-lg text-[#6D758A] text-sm font-righteous justify-evenly bg-[#131931] border-[#1E284A] border-[2px]">
        <div @click="handleState(1)">
            <button v-if="state != 1">Overview</button>
            <ButtonBox v-if="state == 1" :text="'Overview'" />
        </div>
        <div @click="handleState(2)">
            <button v-if="state != 2">Transactions</button>
            <ButtonBox v-if="state == 2" :text="'Transactions'" />
        </div>

    </div>
    <!--OverView-->
    <div v-if="state == 1" class="text-start pt-5">
        <div class="w-full flex items-center mb-2 py-3 bg-chat-bubble-bg border-[2px] border-[#FFFFFF0D] rounded-xl">
            <img class="pl-3 w-16" src="../../assets/wallet-1.svg" />
            <div class="block max-h-24">
                <div class="font-roboto flex max-h-5 justify-between items-center">
                    <div class="text-text-modal text-sm font-semibold">Total Wagered</div>
                </div>
                <div v-if="data_loading == true" class="pr-2 loading-dots h-[28.5px] pt-2">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
                <div v-if="data_loading == false" class="pr-2 text-[19px] font-mono">
                    <span class="text-white font-black">{{ dashboardStore.statistics &&
                        dashboardStore.statistics.data &&
                        '$' + dashboardStore.statistics.data.totalPayout }}</span>
                </div>
            </div>
        </div>
        <div class="w-full flex items-center mb-2 py-3 bg-chat-bubble-bg border-[2px] border-[#FFFFFF0D] rounded-xl">
            <img class="pl-3 w-16" src="../../assets/money-1.svg" />
            <div class="block max-h-24">
                <div class="font-roboto flex max-h-5 justify-between items-center">
                    <div class="text-text-modal text-sm font-semibold">Total Bets</div>
                </div>
                <div v-if="data_loading == true" class="pr-2 h-[28.5px] loading-dots ">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
                <div v-if="data_loading == false" class="pr-2 text-[19px] font-mono ">
                    <span class="text-white font-black">{{ dashboardStore.statistics.data
                        ? dashboardStore.statistics.data.totalRounds : 0 }}</span>
                </div>
            </div>
        </div>
        <div class="w-full flex items-center mb-2 py-3 bg-chat-bubble-bg border-[2px] border-[#FFFFFF0D] rounded-xl">
            <img class="pl-3 w-16" src="../../assets/gift-1.svg" />
            <div class="block max-h-24">
                <div class="font-roboto flex max-h-5 justify-between items-center">
                    <div class="text-text-modal text-sm font-semibold">Total Games</div>
                </div>
                <div v-if="data_loading == true" class="pr-2 text-[19px] font-mono h-[28.5px] loading-dots">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
                <div v-if="data_loading == false" class="pr-2 text-[19px] font-mono">
                    <span class="text-white font-black">{{ dashboardStore.statistics.data
                        ? dashboardStore.statistics.data.totalGames : null }}</span>
                </div>
            </div>
        </div>

    </div>
    <!--Settings-->
    <div v-if="state == 2" class="text-start pt-4">
        <div class="text-base pb-1 font-bold text-white font-proxima">Transaction History</div>


        <div class="flex">
            <table class="table-fixed w-full rounded-lg border-spacing-5 mt-2" style="background-color: #191f3b">
                <thead>
                    <tr class="h-12 text-base font-bold" style="color: #424d71">
                        <th class="border-b border-dropdown-1">Type</th>
                        <th class="border-b border-dropdown-1">Amount</th>
                        <th class="border-b border-dropdown-1">Currency</th>
                        <th class="border-b border-dropdown-1">Status</th>
                    </tr>
                </thead>
                <tbody v-if="depositDocs">
                    <tr v-for="(item, index) in combinedArray" :key="index" class="h-10 text-xs px-3">

                        <td class="border-b border-dropdown-1">
                            <div class="flex justify-center items-center">
                                {{ item.wallet_address ? 'Withdrawal' : 'Deposit' }}
                            </div>

                        </td>
                        <td class="border-b border-dropdown-1">
                            <div class="flex justify-center items-center">
                                {{ item.amount.toFixed(4) }}
                            </div>
                        </td>
                        <td class="border-b border-dropdown-1">
                            <div class="flex justify-center items-center">
                                {{ item.currency.toUpperCase() }}
                            </div>
                        </td>


                        <td class="border-b border-dropdown-1">
                            {{ }}
                            <div :class="item.status.toLowerCase() && item.status == 'finished' && okStatuses.includes(item.status) ? 'text-green-400' : !okStatuses.includes(item.status) && item.status != 'failed' ? 'text-orange-400' : 'text-red-400'"
                                class="flex justify-center items-center">
                                {{ okStatuses.includes(item.status)
                                ? "Finished"
                                : item.status === "failed"
                                ? "Failed"
                                : "Waiting" }}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
    <!--Change Password-->
    <div v-if="state == 3" class="text-start pt-5">
        <div class="text-base pb-1 font-bold text-white font-proxima">Old Password</div>
        <div class="text-[13px] font-bold text-[#6D758A] font-proxima">Enter your old password</div>
        <div class="pt-1 password">
            <TextBoxVue :type="'text'" :profile="true" :placeHolder="'Old Password'" />
        </div>
        <div class="text-base pb-1 pt-4 font-bold text-white font-proxima">New Password</div>
        <div class="text-[13px] font-bold text-[#6D758A] font-proxima">Create new password</div>
        <div class="pt-1 password">
            <TextBoxVue :endIcon="'eye-off'" :type="'password'" :profile="true" :placeHolder="'Create new Password'" />
        </div>
        <div class="text-base pb-1 pt-4 font-bold text-white font-proxima">Repeat Password</div>
        <div class="text-[13px] font-bold text-[#6D758A] font-proxima">
            Repeat the new password again
        </div>
        <div class="pt-1 password">
            <TextBoxVue :endIcon="'eye-off'" :type="'password'" :profile="true" :placeHolder="'Repeat new Password'" />
        </div>
        <div class="text-start">
            <div class="flex pt-6 pb-8">
                <ButtonBox :text="'Save Changes'" :icon="'chevron-right'" />
            </div>
        </div>
    </div>
</template>

<script setup>
import ButtonBox from '@/components/form/ButtonBox.vue'
import TextBoxVue from '@/components/form/TextBox.vue'
import { auth } from '@/main';
import { depositDocs, docsData } from '@/main';
import { useDashboardStore } from '@/store/dashboardStore';

import { ref, onMounted, computed } from 'vue'
const dashboardStore = useDashboardStore()
const state = ref(1)
const state_transactions = ref(1)
const displayName = ref(auth.currentUser.displayName)
const data_loading = ref(false)
const handleState = val => {
    state.value = val

}
const okStatuses = ["partially_paid", "confirmed", "finished"];
const combinedArray = computed(() => {

    return [...depositDocs, ...docsData]
})

const handleStateTransactions = val => {
    state_transactions.value = val
}

onMounted(async () => {
    data_loading.value = true
    dashboardStore.getStatistics().then(async () => {
        data_loading.value = false
        console.log("DEPOSITS", depositDocs, docsData, data_loading.value)
    })
})
</script>

<style scoped>
/* Custom checkbox tick color */
input[type='checkbox']:checked {
    background-color: #131931;
    /* Green background when checked */
    border-color: #1e284a;
    /* Green border when checked */
    box-shadow: 0px 12px 16px 0px #0000000f;
}

input[type='checkbox']:disabled:focus {
    box-shadow: none;
    border-color: #1e284a;
    /* Green border when checked */
}

input[type='checkbox']:focus {
    box-shadow: none !important;
}

input::placeholder {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
        Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.custom-checkbox input[type='checkbox'] {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #131931;
    border: 2px solid #1e284a;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.custom-checkbox input[type='checkbox']:checked {
    border-color: #1e284a;
    /* Green border when checked */
}

.custom-checkbox input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 39%;
    left: 47%;
    transform: translate(-50%, -50%) rotate(44deg) scaleX(1);
    width: 6px;
    height: 11px;
    border: solid #00ff94;
    box-shadow: 0px 0px 0px 0px #00ff9473;
    border-width: 0 3px 3px 0;
}

.custom-checkbox label {
    font-size: 11px;
    padding-left: 5px;
}

/* Hide default checkbox appearance and style the new one */
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.5rem;
    accent-color: #4caf50;
    /* Change tick color */

    height: 1.5rem;
    background-color: #131931;
    /* Green background when checked */
    border-radius: 0.375rem;
    /* Tailwind rounded */
    border-color: #4caf50;
    /* Green border when checked */
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;
}

.input-container input::placeholder {
    color: white;
}

.password input::placeholder {
    color: #47516a;
}

.password {
    position: relative;
    width: 100%;
}

.end-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-container input {
    width: 100%;
    padding: 8px 20px 8px 20px;
    /* Adjust padding to make room for icon and pipe */
    border-width: 2px;
    font-size: 16px;
    box-sizing: border-box;
    min-width: 170px;
}

.password input {
    width: 100%;
    padding: 8px 20px 8px 20px;
    /* Adjust padding to make room for icon and pipe */
    border-width: 2px;
    font-size: 16px;
    box-sizing: border-box;
    min-width: 170px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 29px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

input:checked+.slider {
    border-color: red;
    background: linear-gradient(180deg, rgba(255, 239, 3, 0.7) 0%, rgba(211, 117, 30, 0.7) 100%);
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.loading-dots {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #d3d3d3;
    border-radius: 50%;
    animation: loading 0.6s infinite alternate;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes loading {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.5);
        opacity: 0.5;
    }
}
</style>
